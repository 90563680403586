@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .activity_alert_template {
    width: 100%;
  }
}

.activity_alert_template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  margin-top: 20px;
  flex: 1;
  margin: 10px;
  font-size: 18px;
}

.activity_alert_template-import {
  background: #36B4E5;
}

.activity_alert_template-export {
  background: #D90348;
}

.activity_alert_template-transhipment {
  background: #FFB81D;
}

.activity_alert_template-return-to-shipper {
  background: #173A64;
}

.activity_alert_template-header {
  font-size: 18px;
  color: white;
  margin: 10px 0 10px 10px;
  font-weight: bold
}

.status-alert-configuration-cell {
  flex-basis: 100%;
  min-width: 100px;
  text-align: center;
  color: #334D70;
  margin-bottom: 10px;
  align-self: flex-end;
}

.activity_alert_template-content {
  background: #ffffff;
  display: flex;
}

.alert-template-status-name {
  padding: 15px;
  margin: 0;
  color: #173A64;
  white-space: pre-wrap;
  font-size: 18px;
}

.status-alert-configuration {
  text-align: center;
}

.status-alert-configuration-item {
  display: inline-block;
  padding: 5px;
}

.status-alert-configuration-item label {
  display: block;
}

.status-alert-configuration-item input {
  display: inline-block;
}

.status-alert-configuration-item input:enabled {
  outline: 1px solid #173A64;
  outline-offset: -2px;
  height: 15px;
  width: 15px;
}

.activity_alert_template-content button {
  margin-right: 20px;
  align-self: flex-end;
  margin-bottom: 20px;
}

.activity_alert_template-lcol{
  width: 100%;
  text-align: left!important;
}

.activity_alert_template-table{
  border-collapse: collapse;
  background: #ffffff
}
.activity_alert_template-table td{
  padding: 15px;
  text-align: center;
}
.activity_alert_template-table tbody tr{
  border-bottom: 1px solid #173A6430
}
/* 
.activity_alert_template-table tbody tr:last-child{
  border-bottom: none
} */

.activity_alert_template-buttonRow{
  margin:10px;
  display: flex;
  justify-content: flex-end 
}