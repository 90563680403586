@media screen and (min-width: 500px) and (max-width: 999px){

	.app-modal {
		display: block;
		padding: 2%;
		top: 40%;
		bottom: auto;
		min-width: 87%;
		max-width: 87%;
		line-height: initial;
	}
}

@media screen and (max-width: 499px) {

	react-app {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		min-height: calc(100% - 91px);
		overflow: hidden;
		background-image: url("./../../static/images/login_page_background.png");
	}

	nav {
		padding-bottom: 100px;
	}


	div.main_wrap > h2,
	.main_wrap-header > h2 {
		grid-column-start: 1;
		grid-column-end: 3;
		color: #36B4E5;
		font-size: 34px;
		margin: 0;
	}

	.app-modal {
		display: block;
		background: #fff;
		border: 5px solid;
		color: #173A64;
		padding: 2%;
		position: absolute;
		top: 50%;
		left: 50%;
		bottom: auto;
		transform: translate(-50%, -50%);
		min-width: 87%;
		max-width: 87%;
		outline: none;
		font-size: 16px;
		line-height: initial;
	}

  .ReactModal__Overlay {
    overflow-y: scroll;
  }

	.app-modal h4 {
		font-size: 35px;
		line-height: 40px;
		margin: 10px 0 0;
		color: #173A64;
	}

	.app-modal .button + .button {
		margin-left: 20px;
	}

	.app-modal__type_warning, .app-modal__type_warning a {
		color: #D90348;
	}

	#close-modal {
		position: absolute;
		top: 5px;
		right: 5px;
		color: #173A64;
	}

	.info {
		width: 25px;
		height: 25px;
		border: 2px solid #173a64;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}

	/*common policy page style start*/
  div.policy-page section {
    padding: 0 20px 20px;
  }
	div.policy-page {
		padding: 20px 20px 300px 20px !important;
	}
	section > ol, section > h2 > ol  {
		padding: 0;
	}
	div.policy-page h1 {
		font-size: 20px;
		padding: 5px;
	}
	div.policy-page h2 ol {
		font-size: 18px;
	}
	div.policy-page ol h3 {
		font-size: 16px;
	}
	div.policy-page table {
		word-break: break-word;
		white-space: -moz-pre-wrap;
	}
	table.adaptive-table, table.adaptive-table thead, table.adaptive-table tbody, table.adaptive-table th, table.adaptive-table td, table.adaptive-table tr {
		display: block;
	}
	table.adaptive-table th {
		display: none;
	}
	table.adaptive-table td {
		border: none;
		border-bottom: 1px solid black;
		position: relative;
		padding-left: 50%;
		text-align: left;
		display: flex;
	}
	table.adaptive-table td:last-child{
		border-bottom: none;
	}
	table.adaptive-table td:before {
		position: absolute;
		top: 0;
		left: 6px;
		width: 45%;
		border-right: 1px solid black;
		height: 100%;
		color: #173A64;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
	table.adaptive-table tr:nth-child(even) {
		background-color: rgba(179, 179, 179, 0.5);
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#3AB5E6', endColorstr='#3AB5E6');
	}
	table.privacy-policy-table-9 td {
		font-size: 15px;
	}
	table.privacy-policy-table-9 td, th {
		padding: 2px;
	}
	.policy-page a {
		word-break: break-all;
	}
	.policy-page ul{
		padding: 0 0 0 20px !important;
	}
  .policy-page table{
    border: none !important;
  }
	/*common policy page style finish*/


	/*modals css start*/
	.registration-confirmation h4, .email-confirmation h4, .password-reset_success h2, .notification h4 {
		padding: 25px 10px !important;
		font-size: 23px !important;
		line-height: 34px !important;
		word-break: break-word;
	}
	/*modals css start finish*/

}
