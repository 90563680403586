@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.react_app_wrap {
		height: auto !important;
		position: relative;
		flex: 2 0 auto;
	}
	.logged-in-layout__navbar-item {
		position: relative;
	}
	.content_footer_wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.logged-in-layout__navbar + div.policy-page {
		background-color: #F0EEEE;
	}
}

.content_footer_wrapper{
	grid-column: 1/4;
	-ms-grid-column: 1;
	-ms-grid-column-span: 4;
	-ms-grid-row: 2;
	-ms-grid-row-span: 2;
	background: #F0EEEE none;
}

.react_app_wrap{
	height: 100%;
	width: 100%;
	-ms-grid-row: 2;
	-ms-grid-rows: 1fr;
	/*background: #D2D1D1;*/
	display: grid;
	display: -ms-grid;
	grid-template-columns: 80px 80px repeat(24, 1fr);
	-ms-grid-columns: 80px 80px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-column-start: 1;
	grid-column-end: 10;
	font-variant-ligatures: none;
}

.logged-in-layout__navbar {
	background: #D2D1D1;
	grid-column-start: 1;
	-ms-grid-column: 1;
	grid-column-end: 3;
	-ms-grid-column-span:2;
}

.logged-in-layout__navbar + div.main_wrap, .logged-in-layout__navbar + div.dashboard_wrap {
	grid-column-start: 3;
	-ms-grid-column: 3;
	grid-column-end: 27;
	-ms-grid-column-span: 24;
}

.logged-in-layout__navbar_collapsed {
    grid-column-end: 2;
	-ms-grid-column-span: 1;
}

.logged-in-layout__navbar_collapsed + div.main_wrap, .logged-in-layout__navbar_collapsed + div.dashboard_wrap {
	
	grid-column-start: 2;
	-ms-grid-column: 2;
	grid-column-end: 27;
	-ms-grid-column-span: 25;
}

.logged-in-layout__navbar ul {
	width: 163px;
	overflow-x: hidden;
	margin: 0;
	padding: 25px 0;
	list-style: none;
}

.logged-in-layout__navbar_collapsed ul {
  width: 63px;
}

.logged-in-layout__navbar li+li {
	margin-top: 25px;
}

.logged-in-layout__navbar-item-special,
.logged-in-layout__navbar-item {
	display: block;
	border: none;
	background: transparent;
	text-decoration: none;
	color: #004070;
	font-weight: normal;
	font-size: 15px;
	line-height: 34px;
	padding-left: 64px;
	cursor: pointer;
	outline: none;
	max-height: 35px;
}

.logged-in-layout__navbar-item_disabled svg{
	fill: #afafaf !important;

}

.logged-in-layout__navbar-item_disabled {
	color: #afafaf !important;
}

.alert-template-feature-hint-wrapper {
	position: absolute;
  min-width: 260px;
}

.alert-template-feature-hint-wrapper .react-hint {
  min-width: 200px;
}

.logged-in-layout__navbar-item-special > svg {
	stroke: #004070;
}

.logged-in-layout__navbar-item-special_active svg {
	stroke: #004070;
}

.logged-in-layout__navbar-item-special svg,
.logged-in-layout__navbar-item svg {
	fill: #004070;
	position: absolute;
	left: 17px;
}

.logged-in-layout__navbar-item-special_active,
.logged-in-layout__navbar-item_active {
	color: #36b4e5;
}

.logged-in-layout__navbar-item_active svg {
	fill: #36b4e5;
}

@media screen and (min-width: 500px) {

	div.main_wrap {
		padding: 30px 30px 180px 30px;
		display: grid;
		display: -ms-grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: fit-content(200px);
		-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-row-gap: 20px;
		grid-column-gap: 20px;
	}
}

div.dashboard_wrap{
	display: grid;
	/* display: -ms-grid; */
	grid-column: 2;
	-ms-grid-column: 2;
	grid-template-rows: fit-content(200px);
}

.sign-out {
	position: absolute;
	right: 30px;
	margin-top: -25px;
	color: black;
}
