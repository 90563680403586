@media screen and (max-width: 499px) {
  .advanced_feature_page_id {
    grid-column: 1/27;
    padding: 30px 30px 300px 30px;
  }

  .advanced_feature_header > h2 {
    font-size: 26px !important;
  }

  .advanced_feature_header > h3 {
    font-size: 18px !important;
    font-weight: bold;
    margin-top: 0;
  }

  div.advanced_feature_content{
    flex-flow: column nowrap;
  }

  div.advanced_feature_content > div:nth-of-type(1),
  div.advanced_feature_content > div:nth-of-type(2) {
    margin: 0;
  }

  div.advanced_feature_content section, .advanced_feature-video-wrapper {
    margin-bottom: 15px;
  }

  .frame-section_content > p:first-of-type, .frame-section_content > ul {
    margin-top: 0;
  }

  .frame-section_content > p:last-of-type, .frame-section_content > ul {
    margin-bottom: 0;
  }
}