.delete-account h4{
  font-size: 24px;
}

div.delete-account-button > h4,
div.delete-account-button > p {
  text-align: center;
}

div.delete-account-button > p:last-child {
  margin-top: 30px;
  text-align: center;
}

div.connection-error {
  grid-column: 1 / 11;
  -ms-grid-column: 1;
  -ms-grid-column-span: 10;
  -ms-grid-row: 7;
  font-size: 13px;
  color: red;
  width: 100%;
  text-align: center;
}