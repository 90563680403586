@media screen and (max-width: 499px) {

  .page_id_containers-history {
    grid-column: 1/27;
    padding: 10px 10px 300px;
  }

  .page_id_containers-history > h2 {
    font-size: 26px !important;
  }

  .container-history-card-wrapper-controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 15px;
  }

  div.page_id_containers-history-controls {
    text-align: center;
  }

  .container-history-card-search-result-container {
    margin: 15px 0 15px;
    color: #173A64;
  }

  .container-history-card-collapsible-trigger {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: white;
    border: 1px solid #173A64;
    padding: 5px;
  }

  .container-history-card-collapsible-trigger > span {
    align-self: center;
  }

  .container-history-card-search-result-container > div {
    margin-bottom: 5px;

  }

  .container-history-card-status-header {
    font-weight: bold;
    margin: 10px 0 10px;
  }

  .container-history-card-status-list-container {
    font-size: 14px;
  }

  .container-history-card-status-name{
    display: inline-block;
    width: 50%;
    white-space: normal;
    word-break: break-word;
  }

  .container-card_advanced-view_container-statuses-container>div:nth-child(2n) {
    background-color: #d6e6eb;
  }

  .container-history-card-journey {
    border-bottom: 1px solid #E1E1E1;
  }

  .container-history-card-journey:last-child {
    padding-bottom: 10px;
    border-bottom: none;
  }

  .container-history-card-journey-history {
    border: 1px solid #173A64;
    border-top: none;
    background-color: white;
    padding: 5px;
  }

  .container-history-card-journey-history > p {
    margin: 5px 0 5px;
  }

  .container-history-card-collapsible-trigger > svg {
    fill: #173A64;
  }

  .container-history-card-journey-status {
    margin-bottom: 10px;
  }

  .container-history-card-journey-status-alerts {
    margin-left: 10%;
    font-size: 12px;
    word-break: break-all;
  }

  .container-history-card-journey-status-alerts > div > span:first-of-type {
    display: inline-block;
    width: 35px;
  }

  .container-history-card-status-time {
    vertical-align: top;
  }
}