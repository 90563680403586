@supports (-ms-ime-align:auto) {
  .reg_main_wrap section {
    margin: 70px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .reg_main_wrap {
    flex-grow: 2;
  }

  .reg_main_wrap section {
    margin: 70px 0 0 0;
  }
}

label.required:after {content: " *";}

div.reg_main_wrap, div.page_id_register {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 30px 30px 180px 30px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
 -ms-grid-row: 2;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 10;
  -ms-grid-column-span: 12;
  grid-template-rows: fit-content(200px);
}

.page_id_register > h1 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
  background-color: #36b4e5;
  margin: 0;
  padding: 10px 0 10px 40px;
  color: #fff;
  text-align: center;
}

.page_id_register section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
}

.page_id_register form {
  padding: 25px 10px;
}

.page_id_register fieldset {
  border: none;
  width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.page_id_register legend {
  font-weight: bold;
  padding:40px 0 0 30px;
}

.page_id_register fieldset>div {
  padding: 0 15px 0 235px;
  position: relative;
}

.page_id_register fieldset>div + div {
  margin-top: 10px;
}

.page_id_register fieldset>div label {
  position: absolute;
  left: 0px;
  width: 215px;
  text-align: right;
  font-weight: bold;
  line-height: 34px;
}

.page_id_register fieldset>div select {
  background: #fff;
}


.page_id_register fieldset>div input,
.page_id_register fieldset>div select {
  height: 34px;
  width: 100%;
  box-sizing: border-box;
  text-indent: 10px;
  border: 0.1px solid #D2D1D1;
}

.page_id_register fieldset>div input[name="containers-number"]{
  width: 60px;
}


.page_id_register fieldset>div input:focus,
.page_id_register fieldset>div select:focus {
  border: 0.1px solid #FFB81D;
}

.page_id_register fieldset>div input.is-invalid-input {
  border: 0.1px solid #D90348;
}

.registration__role-in-company > div > :nth-last-child(2),
.registration__business-type > div > :nth-last-child(2),
.registration__security-question > div > :nth-last-child(2) {
  border: 0.1px solid #D90348;
}

.page_id_register fieldset>div .form-error {
  color: #D90348;
  font-size: 12px;
}

.page_id_register fieldset>div label.required:after {
  content: ' *';
  color: red;
}

.page_id_register fieldset:nth-child(1) {
  float: left;
}

.page_id_register form>div {
  clear: both;
  float: right;
  padding: 10px 20px;
}

.page_id_register fieldset>div input[name*="password"],
.page_id_register fieldset>div input[name="contact-number"]{
  width: 50%;
}

.page_id_register fieldset>div input[name="postcode"]{
  width: 25%;
}

.page_id_register fieldset>div input[name*="password"] + span,
.page_id_register fieldset>div input[name="contact-number"] + span,
.page_id_register fieldset>div input[name="postcode"] + span {
  display: block;
}

.page_id_register fieldset>div label[name='containers-number']{
  width: auto;
  left: 0;
  right: 100px;
  font-weight: bold;
}
.page_id_register fieldset>div label[name='containers-number']:after{
  content: '';
}
label[name='containers-number'] + div {
  text-align: right;
}

.info-password {
  position: relative;
  margin-bottom: -30px;
  left: 55%;
}

.info-password:hover::after {
  position: absolute;
  content: 'Password must contain at least 8 characters including at least 1 number and 1 uppercase letter.';
  background-color: #173a64;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  width: 200px;
  left: 40px;
  z-index: 1;
}

.registration__button_submit {
  background-color:  #D90348;
  color: #fff;
  border: none;
  padding: 0 34px 0 14px;
  font-weight: bold;
  font-size: 17px;
  line-height: 34px;
  height: 34px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.registration__button_submit svg{
  position: absolute;
  right: 12px;
  top: 10px;
  fill: #fff;
}

.registration__button_submit:disabled {
  background-color: #828282;
  opacity: 0.5;
  cursor: not-allowed;
}

.visibleDiv{
}

.invisibleDiv{
  display: none;
}

.registration_button_back {
  margin-right: 20px;
}

.register__button_row{
  float: right;
}

.register__update_container{
  padding: 15px!important;
  margin: 15px!important;
  margin-left: 50px!important
}

.register__update_container label{
  width: auto!important;
  position: static!important;
}

.register__update_container input{
  height: auto!important;
  width: auto!important;
}

.register__flex-ports label{
  font-weight: normal!important;
}

.register__centered{
  text-align: center
}

.register__flex-ports{
  display: flex;
  justify-content: space-around;
  
}

.register__flex-updates{
  display: flex;
  justify-content:center
}

.register__flex-input-item{
  display: flex;
  align-items: center
}

.register__header{
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 18px;
}