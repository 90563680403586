div.button_account_active_disabled button {
  cursor: not-allowed;
  height: 40px;
}

button.button_color_account_active_enabled {
  border: 3px green solid;
  background-color: white;
  height: auto;
}

div.button_account_suspended_disabled button {
  cursor: not-allowed;
  height: 40px;
}

button.button_color_account_suspended_enabled {
  border: 3px #D90348 solid;
  background-color: white;
  height: auto;
}

.suspend_account_buttons {
  width: 100%;
}

.suspend_account_buttons td:nth-child(1) {
  width: 100%;
}

.suspend_account_buttons td:nth-child(2) {
  text-align: right;
  min-width: 400px;
}

.suspend_account_buttons div:nth-child(1){
  display: inline-block;
  max-width: 200px;
  margin-right: 10px;
}

.suspend_account_buttons div:nth-child(2){
  display: inline-block;
  max-width: 200px;
}