@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_history {
    overflow-y: scroll;
  }

  .history-search-instructions {
    margin-bottom: 20px !important;
  }

  .history-search-area {
    flex: 1 !important;;
  }
}

@supports (-ms-ime-align:auto) {
  .history-search-instructions {
    margin-bottom: 20px !important;
  }
}

.page_id_history {
  background: #F0EEEE;
}

.page_id_history-content {
  /* display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-column: 1/12;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  -ms-grid-row: 3;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.history-search-header-instructions {
  color: #173A64;
  font-size: 18px;
}

.history-search-button {
  width: 100%;
  margin-top:15px
}

.history-search-form {
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
  display: flex;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.history-search-form > div{
  padding: 10px;
}

.page_id_history-textarea-column{
  flex: 2;
  display: flex;
  flex-direction: column;
  min-width: 160px; 
}
.page_id_history-date-column{
  flex: 2;
  min-width: 240px; 
}
.page_id_history-filter-column{
  flex: 1;
  min-width: 200px; 
}

.page_id_history-textarea-column > div{
  height: 100%;
}
.page_id_history-box-header{
  display: block;
  color: #173A64;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.history-search-select-control{
  height: 34px;
  width: 100%;
  box-sizing: border-box;
  text-indent: 10px;
  border: 1px solid #ccc;
  color: #173a64;
  background: #fff;
  font-size: 14px;
  /* min-width: 200px; */
}

.history-search-containers-text-area {
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: none;
  height: 110px;
  overflow-y: scroll;
  padding: 10px;
}

.page_id_history-datepicker{
  display: flex;
  justify-content: space-between;
  height: 150px;
  
}

.page_id_history-datepicker-item{
  width: 48%;
  min-width: 115px;
}
.react-datepicker-wrapper, .react-datepicker__input-container{
  width: 100%; 
}

.page_id_history-clear-icon{
  color: white;
  position: absolute;
  bottom: 2px;
  right: 4px;
  font-size: 12px;
  cursor: pointer;
}
.page_id_history-clear-icon-background{
  border: 15px solid transparent;	
  border-right: 15px solid #173a64; 
  border-bottom: 15px solid #173a64;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.page_id_history-datepicker-area{
  color: #173a64;
  background:#F6F4F4;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
  cursor: pointer;
}
.page_id_history-datepicker-description{
  display: block;
  color: #B9B9B9;
  font-size: 13px;
  margin-top: 10px
}

.page_id_history form > span .form-error {
  text-align: left;
  color: #D90348;
  font-size: 12px;
}


#history-datepicker-wrapper {
  pointer-events: none;
}

.page_id_history-plus-icon{
  font-size: 40px;
}

.history-datepicker-wrapper div {
  margin-right: 7px;
  display: inline-block;
  pointer-events: none;
}

.history-datepicker-icon {
  margin-bottom: -10px;
  width: 34px;
}

.history-search-form-wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 5% 0 5%;
}

.history-search_search-date-and-filters-wrapper > div:first-of-type {
  width: 50%;
}

.history-datepicker-input-invalid {
  box-shadow: inset 0 0 0 1px #D90348;
  -webkit-box-shadow: inset 0 0 0 1px #D90348;
  -webkit-appearance: none;
  border: none;
}

.container-history-table_search-mode-container {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-history-table_search-mode-option {
  width: 50%;
  text-align: center;
}

.container-history-table_search-mode-option input {
  width: 20px;
  height: 20px;
}

.container-history-table_search-mode-option-description {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 5% 15px;
  text-align: center;
}

.container-history-table_search-mode-option-description p {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.mode-option-description-selected {
  border: 2px solid #36B4E5;
}

.history-datepicker-input {
  -webkit-text-fill-color: #173A64;
}

.history-datepicker-input,
.date-from,
.date-to,
.blue_calendar .react-datepicker__current-month,
.blue_calendar .react-datepicker__day-name,
.blue_calendar .react-datepicker__day {
  color: #173A64;
}

.blue_calendar .react-datepicker__navigation--previous {
  border-right-color: #173A64;
}

.blue_calendar .react-datepicker__navigation--next {
  border-left-color: #173A64 !important;
}

.blue_calendar .react-datepicker__day--disabled {
  color: #ccc !important;
}

.blue_calendar .react-datepicker__day--selected {
  color: white !important;
}

.container-history-table_search-mode-option {
  margin-left: 5%;
  margin-right: 5%;
}
