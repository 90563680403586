div.buttonDiv {
  width: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row: 8;
  -ms-grid-row: 1;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

div.buttonCancel{
  grid-column-start: 4;
  grid-column-end: 4;
  -ms-grid-column: 6;
}

div.buttonUpdate{
  grid-column-start: 6;
  grid-column-end: 6;
  -ms-grid-column: 7;
}

div.button_general{
  position: center;
  text-indent: 30px;
  width: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  div.buttonDiv {
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-row: 8;
    -ms-grid-row: 1;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  div.buttonCancel{
    grid-column-start: 5;
    grid-column-end: 5;
    -ms-grid-column: 5;
  }

  div.buttonUpdate{
    grid-column-start: 6;
    grid-column-end: 6;
    -ms-grid-column: 6;
  }
}