@media screen and (max-width: 499px) {
  .frame-section_title {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  ul.advanced_feature_important_content {
    font-size: 22px;
    padding-left: 25px;
  }
}