@supports (-ms-ime-align:auto) {
  .login_page_contact_helpdesc {
    padding-top: 11%;
  }

  .login__button_sign_in svg{
    margin: auto;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_login form div:nth-child(3) {
    margin: 5px 0;
  }

  .login_page_contact_helpdesc {
    padding-top: 11%;
  }

  .div.main_wrap, div.page_id_login {
    padding: 30px !important;
  }

  div.login_wrapper {
    padding-bottom: 110px;
    flex: 1 0 auto;
  }
}


@media screen and (min-width: 500px) {
  div.login_wrapper {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(11, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 0fr auto;
    grid-column: 1;
    -ms-grid-column: 1;
    grid-column-end: 10;
    -ms-grid-column-span: 11;
    -ms-grid-row: 2;
  }

  div.main_wrap, div.page_id_login {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(11, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 30px 30px 180px 30px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-row: 1;
  }

  .page_id_login {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 16;
    -ms-grid-column-span: 15;
  }

  .page_id_login section:nth-child(1) {
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 5;
    -ms-grid-column-span: 3;
    grid-row: 2;
  }

  .page_id_login section:nth-child(2) {
    grid-column-start: 5;
    -ms-grid-column: 5;
    grid-column-end: 8;
    -ms-grid-column-span: 3;
    grid-row: 2;
  }

  .page_id_login section:nth-child(3) {
    grid-column-start: 8;
    -ms-grid-column: 8;
    grid-column-end: 11;
    -ms-grid-column-span: 3;
    grid-row: 2;
  }

  .page_id_login section:nth-child(5) {
    grid-column-start: 3;
    grid-row: 11;
    -ms-grid-column: 2;
    grid-column-end: 10;
    -ms-grid-column-span: 10;
    -ms-grid-row: 2;
    grid-row: 3;
  }

  .page_id_login form div:nth-child(3) {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(8, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 0px;
    grid-column-gap: 5px;
  }

  .page_id_login form div:nth-child(3) a {
    text-align: left;
  }

  .not-registered-link-wrapper {
    display: block;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 1;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
  }

  .forgot-password-link-wrapper {
    display: block;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
  }

  .page_id_login form div:nth-child(3) button:nth-child(3) {
    grid-column-start: 8;
    -ms-grid-column: 8;
    grid-column-end: 9;
    -ms-grid-column-span: 1;
    grid-row-start: 2;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
  }

  .page_id_login form label:after {
    content: ':';
  }

  .page_id_login form input {
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    text-indent: 10px;
  }

  .page_id_login form input + .form-error.is-visible {
    color: #D90348;
    font-size: 12px;
  }

  .login_page_contact_helpdesc {
    text-align: center;
  }

  .footer_text {
    grid-row: 5;
  }

  .div.page_id_login.layout-grid > .box {
    flex-basis: 50%;
  }

  .login__error_user_doesnt_exist {
    margin-bottom: 10px;
    font-size: 15px;
    color: red;
    width: 100%;
  }

  .account-suspend-modal {
    text-align: center;
  }

  .login__button_sign_in {
    padding: 0 0 0 33%;
    background-color: #D90348;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 17px;
    line-height: 34px;
    height: 34px;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
  }

  .login__button_sign_in:disabled {
    background-color: gray;
    cursor: not-allowed;
  }

  .login__button_sign_in svg {
    fill: #fff;
  }

  div.react-cookie-banner {
    grid-row: 1 !important;
    -ms-grid-column: 1;
    -ms-grid-column-span: 11;
    grid-column: 1/12 !important;
  }

  .login-page_notification-content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    grid-row: 2;
    -ms-grid-row: 2;
    grid-column: 1/12;
    -ms-grid-column: 1;
    -ms-grid-column-span: 11;
    margin: 0px 0 10px 0;

  }

}

