@media all and (max-width: 800px) {
  div.search-section-bottom, .search-section-bottom button {
    display: block !important;
  }

  .dashboard_contactInfo {
    grid-column: 3/12 !important;
  }
}

@media all and (max-width: 499px) {

  div.dashboard_wrap {
    grid-column: 1/27 !important;
  }

  .section {
    grid-column: 1/13 !important;
  }

  .search-numbers {
    height: 100px !important;
  }

  div.search-section-bottom {
    display: block;
  }

  .dashboard_contactInfo {
    grid-column: 1/13;
    margin-left: 0;
  }

  .search-section-bottom button {
    float: right;
    margin-top: 10px;
  }

  .mobile-section-content {
    flex-direction: row !important;
    grid-template-columns: 1fr 60px;
  }

  .section-info{
    width: calc(100% - 55px);
  }

  .section-link {
    margin-left: 10px;
    margin-top: 0;
  }

  .section-link a {
    float: right;
  }

  .dashboard_contactInfo {
    grid-column: 1/12 !important;
    margin-left: 0 !important;
  }

  .contact-information-row {
    text-align: center;
  }

  .dashboard_my-account-section .button,
  .dashboard_faq-section .button {
    align-self: flex-end;
  }

  .section_mobile_adaptive {
    padding: 15px;
  }

  .dashboard-hint-wrapper .react-hint:first-child {
    width: 55vw !important;
    left: auto !important;
  }

  h2.dashboard_header{
    font-size: 26px !important;
  }

  .dashboard-search-controls-section > span > button{
    padding-right: 25px;
  }

  .header_critical_alert_wrapper {
    width: 100%;
  }

  span.dashboard_alerts-section-wrapper > section,
  span.dashboard_history-section-wrapper > section{
    width: unset !important;
    padding: 10px !important;
  }

  span.dashboard_alerts-section-wrapper,
  span.dashboard_history-section-wrapper{
    grid-column: 1/13 !important;
    flex-direction: column;
  }

  .dashboard_wrap .react-hint__content {
    font-size: 1.15em;
  }

  div.fake-free-alerts-box:hover > span,
  div.fake-free-history-box:hover > span{
    display: none !important;
  }

  div.page_id_dashboard {
    padding-top: 20px !important;
  }

  .page_id_dashboard {
    padding-bottom: 300px !important;
  }
}