@media screen and (max-width: 640px) {
  .header img {
    max-height: 30px;
  }
}

@media screen and (max-width: 499px) {
  .header {
    padding: 5px 30px 30px;
  }

  div.dp-world-link{
    display: inline-block;
  }

  .header img {
    max-height: 22px;
  }

  .menu-header-wrapper img{
    padding-left: 35px;
  }

  .slide-menu {
    margin-left: -55px;
    margin-top: -33px;
  }

  .slide-menu svg {
    position: absolute;
    left: 25px;
    fill: #99999A;
  }

  .slide-menu li {
    display: inline-block;
    padding-left: 35px;
    margin-top: -5px;
  }

  .slide-menu li + li {
    margin-top: 25px;
  }

  .slide-menu ul {
    width: 150px;
    overflow-x: hidden;
    margin: 0;
    padding: 25px 0;
    list-style: none;
  }



  .bm-burger-button {
    position: absolute;
    display: block;
    width: 30px;
    height: 22px;
    left: 30px;
    top: 37px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #D2D1D1;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: calc(100% + 23px) !important;
    overflow: hidden !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #D2D1D1;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    height: calc(100% - 91px) !important;
  }

  /* Individual item */
  .bm-item {
    list-style: none;
  }

  .bm-item a {
    padding-left: 15px;
  }

  .menu-item {
    color: #004070;
  }
  
  .menu-item svg{
    fill: #004070;
  }

  .menu-item_active {
    color: #36b4e5 !important;
  }

  .menu-item_active svg {
    fill: #36b4e5 !important;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    margin-top: -33px;
    height: calc(100% + 23px) !important;
  }

  .menu-item_disabled svg{
    fill: #afafaf !important;
  }

  .menu-item_disabled {
    color: #afafaf !important;
  }

  .alert-template-feature-hint-wrapper .react-hint {
    min-width: 210px !important;
  }
}