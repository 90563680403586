@media screen and (max-width: 499px) {
  table.cookie-policy-table td:nth-of-type(1):before {
    content: "Cookie Name";
  }

  table.cookie-policy-table td:nth-of-type(2):before {
    content: "Description/ Purpose";
  }

  table.cookie-policy-table td:nth-of-type(3):before {
    content: "Category";
  }

  table.cookie-policy-table td:nth-of-type(4):before {
    content: "Duration/ Persistence";
  }

  .not-breakable-link {
    word-break: normal !important;
  }
}