@media screen and (max-width: 499px) {

  react-app {
    min-height: 100%;
  }

  .page_id_faqs .react_app_wrap {
    max-height: fit-content;
  }

  .page_id_faqs h3 {
    margin-top: 20px !important;
    text-align: left;
    font-size: 26px;
    color: #36B4E5;
  }

  div.page_id_faqs {
    min-width: 101%;
    grid-column-start: 1;
    grid-column-end: 27;
  }

  .page_id_faqs section {
    text-align: justify;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row: 2;
    padding: 0 20px 20px 20px;
  }

  .page_id_faqs section > div {
    margin-bottom: 10px;
    border-bottom: 1px solid #173A64;;
  }

  .page_id_faqs section > div:last-of-type {
    margin-bottom: unset;
    border-bottom: none;
  }

  a {
    word-break: break-word;
  }

  .page_id_faqs .Collapsible__trigger {
    display: grid;
    color: black;
    font-size: 1.25em;
    font-weight: bold;
    text-decoration: none;
    position: relative;
  }

  .page_id_faqs .Collapsible__contentOuter, .Collapsible__contentInner {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .Collapsible__contentInner p {
    color: #173A64;
    margin-right: 5px;
  }

  .page_id_faqs .is-closed, .is-open {
    grid-template-rows: 1fr;
    grid-template-columns: 10fr 1fr;
  }

  .question {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 5px;
    color: #173A64;
    font-size: 20px;
  }

  .is-closed-arrow, .is-open-arrow {
    position: relative;
    bottom: 5px;
    grid-column-start: 2;
    grid-column-end: 3;
    background: no-repeat center;
    grid-row-gap: 5px;
  }

  .is-closed-arrow {
    background-image: url('./../../static/images/chevron-down-dark-blue.svg');
  }

  .is-open-arrow {
    background-image: url('./../../static/images/chevron-up-dark-blue.svg');
  }

  .page_id_faqs section {
    margin-bottom: 300px;
  }

}