@media screen and (max-width: 499px) {
  /* start of phone styles */

  div.login_wrapper{
  }

  div.main_wrap, div.page_id_login {
  }

  .page_id_login .Collapsible__trigger {
    display: block;
    color: #36B4E5;
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 20px;
    background: white;
  }

  .page_id_login .Collapsible__trigger:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 20px;
    height: 28px;
    width: 28px;
    transition: transform 300ms;
  }

  .page_id_login .Collapsible__trigger.is-closed:after {
    background-image: url('./../../static/images/chevron-down-blue.svg');
  }
  .page_id_login .Collapsible__trigger.is-open:after {
    background-image: url('./../../static/images/chevron-up-blue.svg');
  }
  .page_id_login {
    padding: 30px 30px 300px 30px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .page_id_login section:nth-child(1) {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row: 2;
    padding: 0 20px 20px 20px;
  }

  .page_id_login section:nth-child(2)
  , .page_id_login section:nth-child(3)  {
    grid-column-start: 2;
    grid-column-end: 2;
    min-height: 150px;
  }

  .page_id_login form label:after {
    content: ':';
  }

  .page_id_login form input {
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    text-indent: 10px;
  }

  .page_id_login form input + .form-error.is-visible {
    color: #D90348;
    font-size: 12px;
  }

  .login_page_contact_helpdesc {
    text-align: center;
  }

  .footer_text {
    grid-row: 5;
  }

  .div.page_id_login.layout-grid > .box {
    flex-basis: 50%;
  }

  .login__error_user_doesnt_exist {
    /*margin-bottom: 10px;*/
    font-size: 15px;
    color: red;
    width: 100%;
  }

  .account-suspend-modal {
    text-align: center;
  }

  .login__button_sign_in {
    text-indent: 25px;
    text-align: right;
    padding-left: 0;
    background-color: #D90348;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 17px;
    line-height: 34px;
    height: 34px;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
  }

  .login__button_sign_in:disabled {
    background-color: gray;
    cursor: not-allowed;
  }

  .login__button_sign_in svg {
    fill: #fff;
  }

  .not_registered_and_forgot_password {
    display: grid;
    grid-column-gap: 5px;
  }

  .not_registered_and_forgot_password a:nth-child(1) {
    grid-column: 1;
  }

  .not_registered_and_forgot_password a:nth-child(2) {
    grid-column: 2;
  }

  .not_registered_and_forgot_password button {
    grid-column: 3;
  }

  .login-page_critical_alert_wrapper {
    width: 100vw;
  }
}