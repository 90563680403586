@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer__container {
    margin-top: -134px !important;
    height: 134px !important;
    flex-grow: 1 0 auto;
    overflow: visible !important;
    padding: 15px !important;
  }
}

.footer__container {
  box-sizing: border-box;
  grid-row: 3;
  grid-column: 1;
  grid-column-end: 13;
  -ms-grid-row: 4;
  display: flex;
  padding: 15px;
  height: 134px !important;

  background: #022061;
  color: #fff;
  font-size: 9pt;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  z-index: 200;
  margin-top: -134px;
}

.footer__container a {
  color: white;
}

.footer__contact-info{
  flex: 1;
}

.footer__company-name{
  font-weight: 800;
  font-size: 10pt;
}

.footer__helpdesk{
  flex: 1;
  font-weight: 900;
  font-size: 10pt;
}

.footer__links{
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 11pt;
  text-align: right;
}

.footer__links div{
  padding-bottom: 8px;
}

.footer__contact-us{
  color:white;
  font-size: 18px
}
.contact-us__form-item{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.contact-us__form-item div{
  width: 80%;
}

.contact-us__form-item input{
  width: 100%;
  border: 1px solid #D2D1D1;
  padding-left: 7px;
  height: 35px;
  box-sizing: border-box;
  min-width: 200px;
  background: white
}

.contact-us__form-item textarea[name='message']{
  border: 1px solid #D2D1D1;
  box-sizing: border-box;
  padding-left: 7px;
  width: 100%;
  height: 100px;
}
.contact-us__row-button{
  display: flex;
  justify-content: center;
}
.grecaptcha-badge{
  display: none!important
}

.is-invalid-input{
  border: 1px solid #D90348!important;
}