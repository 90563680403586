.button {
	border: none;
	padding: 0 14px;
	font-weight: bold;
	font-size: 17px;
	line-height: 34px;
	height: 34px;
	position: relative;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
}

.button-disabled{
	background-color: #afafaf !important;
  cursor: not-allowed;
}

.button_color_light-blue {
	background-color: #36B4E5;
	color: #fff;
}

.button_color_blue {
	background-color: #173A64;
	color: #fff;
}

.button_color_green {
	background-color: green;
	color: #fff;
}

.button_color_green svg {
	fill: #fff;
}

.button_color_blue svg {
	fill: #fff;
}

.button_color_light-blue {
	background-color: #36B4E5;
	color: #fff;
}

.button_color_light-blue svg {
	fill: #fff;
}

.button_color_pink {
	background-color: #D90348;
	color: #fff;
}

.button_color_yellow{
	background-color: #FFB81D;
	color: #fff;
}

.button_color_transparent svg {
	fill: #173A64;
}

.button_color_transparent {
	background-color: transparent;
}

.button_color_pink svg {
	fill: #fff;
}

.button_color_gray {
	background-color: #828282;
	color: #fff;
}

.button_color_gray svg {
	fill: #fff;
}

.button_icon {
	padding-right: 34px;
}

.button_icon-only {
	text-indent: -99999px;
	padding-left: 0;
}

.button_icon svg {
	position: absolute;
	right: 8px;
	top: 10px;
}

.button a {
	color: inherit;
	text-decoration: none;
	display: block;
	margin: 0 -14px;
	padding: 0 14px;
}

.button_icon a {
	margin-right: -34px;
	padding-right: 34px;
}

.button_icon-only a {
	margin-left: 0;
	padding-left: 0;
}

.button_link {
	display: inline;
	padding: 0;
	font: inherit;
	cursor: pointer;
	background: none;
	text-decoration: underline;
}