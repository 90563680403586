@supports (-ms-ime-align:auto) {
  .page_id_containers-history > h2 {
    margin-bottom: 20px !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_containers-history {
    background-color: #F0EEEE;
  }
}

.container-history-table-wrapper {
  grid-column: 1/13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  -ms-grid-row: 2;
}

.container-history-table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #E1E1E1;
}

.container-history-table th {
  border-top: none;
  background-color: #173A64;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 15px 10px 15px;
  cursor: pointer;
}

.next-status-of-journey > td {
  border-top: 1px solid #E1E1E1;
}

.container-history-table tbody:not(:last-child) {
  border-bottom:  1px solid #173A64;
}

.next-status-of-journey > td,
.container-history-table tbody > tr:first-child > td {
  padding-top: 15px !important;
}

.container-history-table tbody > tr:nth-child(n+2) > td {
  padding: 3px 0 3px;
}

.last-status-of-journey > td,
.container-history-table tbody > tr:last-child > td {
  padding-bottom: 15px !important;
}

.container-history-table td{
  font-weight: bold;
}

.container-history-table td.container-history-table_container-status-column,
.container-history-table td.container-history-table_container-alert-type-column,
.container-history-table td.container-history-table_container-alert-contact-column {
  font-weight: normal;
}

.container-history-table td {
  font-size: 14px;
  color: #003865;
  background-color: #fff;
  vertical-align: top;
}

.container-history-table th.sortable:after {
  content: url('./../../static/icon/sorting/chevron-default.png');
}

.container-history-table th.sorting-order_asc:after {
  content: url('./../../static/icon/sorting/chevron-down.png');
}

.container-history-table th.sorting-order_desc:after {
  content: url('./../../static/icon/sorting/chevron-up.png');
}

.container-history-table td {
  padding-left: 10px !important;
  line-height: 20px;
}

.container-history-table_vessel-column {
  white-space: pre-line;
  vertical-align: top;
}