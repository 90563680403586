div.not-allowed-operation-modal > h4{
  text-align: center;
  padding-bottom: 30px;
}

div.not-allowed-operation-modal > button{
  display: block;
  margin: auto;
  padding: 0 35px;
}
