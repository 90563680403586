@media screen and (max-width: 499px) {

  .page_id_saved-lists button{
    font-size: 12px;
    width: 135px;
  }

  div.page_id_saved-lists {
    grid-column: 1/27;
    padding: 20px 20px 300px 20px;
  }

  .saved-lists-table-of-instructions > tbody > tr {
    line-height: normal;
    font-size: 14px;
  }

  .saved-lists-table-of-instructions > tbody > tr > td:nth-child(2) {
    text-indent: 0;
    padding-left: 5px;
  }

  .saved-lists-name select {
    width: 100%;
    margin: 0 0 5px 0;
  }

  .saved-lists-search-area {
    display: block;
    margin: 0 0 10px 0;
  }

  .saved-lists-search-area div {
    margin-bottom: 10px;
    width: 100%;
  }

  .saved-lists-controls {
    width: 100%;
  }

  .page_id_saved-lists > form{
    min-width: 100%;
    margin-top: 10px;
  }

  .saved-lists-table-of-instructions > tbody > tr > td:first-child {
    white-space: pre;
  }

  .search-area-controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .edit-saved-list-modal input,
  .create-a-new-list-modal input {
    margin-top: 10px;
    width: 100%;
  }

  .edit-saved-list-modal .collapsible-header-trigger,
  .create-a-new-list-modal .collapsible-header-trigger {
    color: #173a64;
  }

  .create-a-new-list-modal .collapsible-header-trigger-wrapper svg {
    fill: #173a64;
  }

  .new-saved-list-content,
  .current-saved-list-content {
    min-height: 180px;
    font-size: 18px;
  }
}