div.returnToSearch {
  position: relative;
  right: 100px;
  left: auto;
  width: 100px;
  grid-column: 12/13;
  grid-row: 5;
  -ms-grid-column: 12;
  -ms-grid-row: 5;
  padding: 20px;
}

.administration-customer-updated {
  color: green;
  text-align: center;
  font-size: 24px;
}

@supports (-ms-ime-align:auto) {
  .page_id_helpdesk_account section {
    margin: 70px 0 0 0;
  }
  .ie_gap {
    height: 0px !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .page_id_helpdesk_account section {
    margin: 70px 0 0 0;
    padding-bottom: 180px;
  }
  .ie_gap{
    -ms-grid-row: 8;
  }
}

.logged-in-layout__navbar + div.page_id_helpdesk_account {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
}

.logged-in-layout__navbar_collapsed + div.page_id_helpdesk_account {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
}

@supports (-ms-ime-align:auto) {
  .page_id_helpdesk_account section {
    margin: 20px 20px 20px 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_helpdesk_account section:nth-child(2) > form > div,
  .page_id_helpdesk_account section:nth-child(3) > form > div {
    margin: 5px 0 5px 0;
  }
  .page_id_helpdesk_account section {
    margin: 20px 20px 20px 0;
  }
}

.page_id_helpdesk_account {
  background: #F0EEEE;
}

.page_id_helpdesk_account {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 15px 30px 180px 30px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;

  grid-template-rows: fit-content(200px);
}

div.administration-title {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
}

.page_id_helpdesk_account > div >  h1 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
  margin: 0;
  color: #36B4E5;
  font-size: 48px;
  text-align: center;
}

.page_id_helpdesk_account div > h2 {
  grid-column-start: 2;
  grid-column-end: 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 13;
  color: #36B4E5;
  font-size: 34px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.page_id_helpdesk_account section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
}

.title {
  grid-column-start: 1;
  grid-column-end: 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  color: #36B4E5;
  font-size: 48px;
  margin-bottom: 20px;
  text-align: center;
}

.page_id_helpdesk_account section:nth-child(2) {
  grid-column: 1 / 7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-row: 2 / 5;
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
}

.page_id_helpdesk_account section:nth-child(2),
.page_id_helpdesk_account section:nth-child(3) {
  background-color: #fff;
  padding: 20px;
}

div.account_notes {
  grid-column-end: 13;
  grid-column-start: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-row: 7;
  -ms-grid-row: 6;
}

div.suspend_account_temp_pass_container {
  grid-column: 7/13;
  -ms-grid-column: 7;
  -ms-grid-column-span: 6;
  -ms-grid-row: 4;
  padding: 20px;
  margin-bottom: 180px;
}

div.delete_account {
  grid-column: 1/14;
  grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-row: 5;
  margin-top: 20px;
}

.page_id_helpdesk_account section:nth-child(2) > h4,
.page_id_helpdesk_account section:nth-child(3) > h4 {
  color: #cb1d4b;
}

.page_id_helpdesk_account section:nth-child(2) > form,
.page_id_helpdesk_account section:nth-child(3) > form {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 0;
  grid-column-gap: 20px;
  color: #173a64;
}

.page_id_helpdesk_account section:nth-child(2) > form > div,
.page_id_helpdesk_account section:nth-child(3) > form > div {
  grid-column: 1 / 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin: 15px 0 5px 0
}

.page_id_helpdesk_account section:nth-child(2) > form > div > div,
.page_id_helpdesk_account section:nth-child(3) > form > div > div {
  display: block;
  align-items: center;
  height: 34px;
  text-indent: 30px;
  grid-column: 4 / 13;
  -ms-grid-column: 4;
  -ms-grid-column-span: 10;
}


@supports (-ms-ime-align: auto) {
  /* Microsoft EdgeV13&14 CSS styles go here */
  .page_id_helpdesk_account section:nth-child(2) > form > div > div,
  .page_id_helpdesk_account section:nth-child(3) > form > div > div{
    text-indent: 9px;
     @include activated;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_helpdesk_account section:nth-child(2) > form > div > div,
  .page_id_helpdesk_account section:nth-child(3) > form > div > div{
    text-indent: 10px;
  }
}

.page_id_helpdesk_account section:nth-child(2) > form > div > label,
.page_id_helpdesk_account section:nth-child(3) > form > div > label {
  display: flex;
  align-items: center;
  grid-column: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
}

.page_id_helpdesk_account section:nth-child(3) > form input,
.page_id_helpdesk_account section:nth-child(2) > form input{
  width: 70%;
  height: 34px;
  color: #173a64;
  text-indent: 10px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-appearance: none;
}

.page_id_helpdesk_account section:nth-child(2) > form input[name="name"],
.page_id_helpdesk_account section:nth-child(2) > form input[name="email"],
.page_id_helpdesk_account section:nth-child(3) > form input[id="unverified"] {
  box-shadow: none;
}

.page_id_helpdesk_account section:nth-child(3) > form select,
.page_id_helpdesk_account section:nth-child(2) > form select {
  height: 34px;
  width: 70%;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  outline: none;
  color: #173a64;
  background: #fff;
  font-size: 14px;
}

.page_id_helpdesk_account section:nth-child(2) > form > div > button,
.page_id_helpdesk_account section:nth-child(3) > form > div > button{
  grid-column: 4 / auto;
  -ms-grid-column: 4;
  -ms-grid-column-span: 12;
  -ms-grid-row: 7;
}

.page_id_helpdesk_account section:nth-child(3) > form button {
  margin-left: 0px;
  -ms-grid-column: 4;
  -ms-grid-column-span: 8;
}

.page_id_helpdesk_account section:nth-child(2) > form > div:last-child,
.page_id_helpdesk_account section:nth-child(3) > form > div:last-child{
  display: flex;
  justify-content: space-between;
  grid-column: 4 / auto;
  -ms-grid-row: 8;
  -ms-grid-column-span: 4;
}

.page_id_helpdesk_account section:nth-child(3) {
  grid-column: 7 / 13;
  -ms-grid-column: 7;
  -ms-grid-column-span: 6;
  grid-row: 2 / 3;
  -ms-grid-row: 2;
}

.page_id_helpdesk_account section:nth-child(4) {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  -ms-grid-row: 5;
}

.page_id_helpdesk_account section:nth-child(3) > form input.is-invalid-input,
.page_id_helpdesk_account section:nth-child(2) > form input.is-invalid-input {
  box-shadow: inset 0 0 0 1px #D90348;
  -webkit-box-shadow: inset 0 0 0 1px #D90348;
  -webkit-appearance: none;
  border: none;
}

.page_id_helpdesk_account form > div .form-error,
.page_id_helpdesk_account section:nth-child(3) > form div .form-error{
  color: #D90348;
  font-size: 12px;
}

.page_id_helpdesk_account section:nth-child(3) > form div:nth-child(1),
.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(1){
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(3) > form div:nth-child(2),
.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(2){
  -ms-grid-row: 2;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(3){
  -ms-grid-row: 3;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(4){
  -ms-grid-row: 4;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(5){
  -ms-grid-row: 5;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(6){
  -ms-grid-row: 6;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(7){
  -ms-grid-row: 7;
}

.page_id_helpdesk_account section:nth-child(3) > form div:nth-child(1) > div {
  -ms-grid-row: 1;
}
.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(1) > div {
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(3) > form div:nth-child(2) > div {
  -ms-grid-row: 1;
}
.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(2) > div {
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(3) > div {
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(4) > div {
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(5) > div {
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(6) > div{
  -ms-grid-row: 1;
}

.page_id_helpdesk_account section:nth-child(2) > form div:nth-child(7) > div{
  -ms-grid-row: 1;
}