@supports (-ms-ime-align:auto) {
	@media screen and (min-width: 500px) {
		.section {
			padding: 40px;
			margin: 20px;
		}
	}
}

@media all and (min-width: 500px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.section {
		padding: 40px;
		margin: 20px;
	}
}

.section {
	padding: 20px;
}

.section_color_blue {
	color: #fff;
	background-color: #173A64;
}

.section_color_light-blue {
	color: #fff;
	background-color: #36B4E5; 
}

.section_color_pink {
	color: #fff;
	background-color: #D90348;
}

.section_color_white {
	background-color: #fff;
	color: #173A64;
}

.section h4 {
	margin: 0;
}

.section > span > p {
	margin-top: 0;
}

.section a {
	color: inherit;
}

.section-content-wrapper {
	 overflow: hidden;
 }

.section-content-wrapper > span {
	display: inline;
}

.section-content-wrapper > span > p:first-of-type {
	margin-top: 0;
}

.section_cursor_pointer {
	cursor: pointer;
}

.flash {
	-webkit-animation: blink 1s linear infinite;
	animation: blink 1s linear infinite;
}
@-webkit-keyframes blink { 50% { opacity: 0; }}
@keyframes blink { 50% { opacity: 0; }}

.section-title-wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.section-title-wrapper .scroll {
	/* Starting position */
	-moz-transform:translateX(-100%);
	-webkit-transform:translateX(-100%);
	transform:translateX(-100%);
	/* Apply animation to this element */
	-moz-animation: scroll-right 15s linear infinite;
	-webkit-animation: scroll-right 15s linear infinite;
	animation: scroll-right 15s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-right {
	0%   { -moz-transform: translateX(100%); }
	100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes scroll-right {
	0%   { -webkit-transform: translateX(100%); }
	100% { -webkit-transform: translateX(-100%); }
}
@keyframes scroll-right {
	0%   {
		-moz-transform: translateX(100%); /* Browser bug fix */
		-webkit-transform: translateX(100%); /* Browser bug fix */
		transform: translateX(100%);
	}
	100% {
		-moz-transform: translateX(-100%); /* Browser bug fix */
		-webkit-transform: translateX(-100%); /* Browser bug fix */
		transform: translateX(-100%);
	}
}

