@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .header {
    display: inline-block;
    -ms-grid-row: 1;
    overflow: visible;
  }

  .header > img {
    margin: 0 !important;
  }
}

div.menu-header-wrapper{
  padding-left: 15px;
  min-width: 250px;
}

.header {
  padding: 15px 20px 30px;
  grid-column: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 9;
  grid-column-end: 13;
  background-color: white;
}

.header a {
  margin: 0;
  float: left;
}

.wmc-logo > img {
  max-height: 40px;
  margin: 0 -10px 0 0;
  float: right;
}

.slide-menu ul:focus {
  outline: none !important;
}