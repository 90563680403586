@supports (-ms-ime-align:auto) {
  .page_id_login form div:nth-child(3) {
    margin: 5px 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_login form div:nth-child(3) {
    margin: 5px 0;
  }
}

.container-table td {
  padding: 15px 10px;
  font-weight: bold;
  font-size: 14px;
  color: #003865;
  background-color: #fff;
}


.container-table tbody:nth-of-type(1){
  border-top: 1px solid #ffffff;
}

.container-table tbody:nth-of-type(n+2){
  border-top: 1px solid #FFB81D;
}

.container-table tbody:after{
  border-top: 1px solid #FFB81D;;
}

.container-table tr:hover td, .container-table tbody:hover td {
  background-color: #FFE2A4;
}

.container-table {
  border-collapse: collapse;
  border-bottom: 1px solid #E1E1E1;
}

.container-table__cell_type_container {
  height: 35px;
}

.container-table__cell_type_port {
  height: 35px;
}

.container-table__status-cell {
  border-right: 1px solid #E1E1E1;
  white-space: nowrap;
}

.container-table tr + tr td {
  border-top: 1px solid #FFB81D;
}

.container-table tr:hover .container-table__cell_type_select {
  border-left: 1px solid #EDD193;
}

.container-table tr:hover .container-table__status-cell {
  border-right: 1px solid #EDD193;
}

th.table-row-selection-column {
  text-align: center !important;
  border-left: none;
}

.container-table th:last-child {
  border-right: none;
}

.container-table__cell_type_select {
  text-align: center;
}

.container-table th {
  border-top: none;
  background-color: #36B4E5;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 15px 10px 15px;
  cursor: pointer;
}

.container-table th:after,
.container-table th.sorting-order_desc:after {
  margin-left: 0.3em;
  color: #334D70;
}

.table-row-status-column {
  border-right: none !important;
}

.table-row-alerts-column {
  border: none;
}

.container-table th.table-row-alerts-column:after ,
.container-table th.table-row-selection-column:after {
  content: '';
}

.container-table th:after {
  content: url('./../../static/icon/sorting/chevron-default.png');
}

.container-table th.sorting-order_asc:after {
  content: url('./../../static/icon/sorting/chevron-up.png');
}

.container-table th.sorting-order_desc:after {
  content: url('./../../static/icon/sorting/chevron-down.png');
}

td.container-table__status-cell {
  font-weight: normal;
}

.container-table__status-cell-icon {
  display: inline-block;
  align-self: center;
}

.container-table__status-cell-icon img {
  padding-right: 20px;
  vertical-align: middle;
}

.container-table__status-cell_info{
  display: inline-block;
  vertical-align: middle;
}

.container-table__actions {
  display: inline-block;
  float: right;
  right: 15px;
  top: 15px;
  width: 125px;
}

.container-table__actions button{
  width: inherit;
}

.container-table__actions-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #D2D1D1;
  position: absolute;
  z-index: 1;
  display: none;
  width: inherit;
}

.container-table__actions-menu li {
  white-space: nowrap;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.container-table__actions-menu li a {
  text-decoration: none;
}

.container-table__actions-menu li:hover {
  background-color: #003865;
}

.container-table__actions-menu li:hover span,
.container-table__actions-menu li:hover a {
  color: #fff;
}

.container-table__actions button svg {
  transform: rotate(90deg);
}

.container-table__actions_active .container-table__actions-menu {
  display: block;
}

.container-table__cell_type_vessel{
  white-space: pre-line;
}

.disabled-link {
  pointer-events: none;
  color: #828282;
}

.enabled-link {
  color: #003865;
}

.changed-container-row td {
  background-color: rgba(58, 181, 230, 0.3);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#3AB5E6', endColorstr='#3AB5E6');
}

.container-table__status-cell_all-status-table td {
  background-color: transparent;
}

.container-table__status-cell_all-status-table > tbody > tr > td:nth-child(2) {
  min-width: 10vw;
  max-width: 10vw;
  white-space: pre-wrap;
}

.container-table-wrapper {
  grid-column: 1/13;

  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
}

.container-table-toolbar {
  min-height: 50px;
  background: #455772 ;
  display: flex;
  justify-content: space-between;
}

.container-table-toolbar-item {
  margin: 15px;
  font-weight: normal;
}

.container-table-remove-containers-from-list,
.container-table-apply-template-alerts-error-popup {
  text-align: center;
  line-height: 35px;
}

.container-table-remove-containers-from-list {
  padding: 10px;
}

.container-table-modal-container-list {
  text-align: left;
  margin: 20px 0 20px;
}

.container-table-apply-template-alerts-error-popup a:visited {
  color: #173A64;
}

.container-table-change-alert-contacts-link {
  cursor:pointer;
  text-decoration:underline;
}


.single-alert-popup-header {
  text-align: center;
}

.container-table-apply-template-alerts-error-popup a:visited {
  color: #173A64;
}

.single-alert-popup-header h2 {
  margin: 5px 0 0;
}

.single-alert-popup-header h3 {
  font-weight: normal;
  margin: 20px 0 10px;
}

.activity_alert_template {
  font-size: 16px;
}

.single-alert-popup .alert-template-status-name {
  font-size: 16px;
  line-height: 14px;
  margin-top: 0;
}

.single-alert-popup-contact-details-primary-contact, .single-alert-popup-contact-details-secondary-contact {
  /* display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center
}

.single-alert-popup-contact-details-primary-contact div:last-child > div:last-child{
  /* margin-right: 15px; */
}

.single-alert-popup-contact-details {
  vertical-align: top;
}

.single-alert-popup-contact-details h3 {
  grid-column: 1/6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  -ms-grid-row: 1;
  color: #cb1d4b;
  text-align: center;
}

.single-alert-popup-contact-details-primary-contact div:nth-child(3) {
  /* margin-left: 55px; */
}

.single-alert-popup-contact-details-primary-input div:nth-child(3){
  margin-left: 0 !important;
}

.single-alert-popup-contact-details h3 > p {
  margin: 0;
  font-size: 14px;
  color: #173A64;
  font-weight: normal;
}

.single-alert-popup-contact-details input[type="checkbox"] {
  margin-left: 15px;
}

.single-alert-popup-contact-details div:nth-child(2){
  grid-column: 1/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 2;
}

.single-alert-popup-contact-details div:nth-child(3) {
  grid-column: 4/6;
  -ms-grid-column: 4;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
}

.single-alert-popup-contact-details-contact-cell {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.single-alert-popup-contact-details-contact-cell label {
  margin-right: 15px;
  width: 40px
}

.single-alert-popup-contact-details-contact-cell div {
  display: inline-block;
}

.single-alert-popup-contact-detail-controls {
  float: right;
}

.single-alert-popup .form-error {
  color: #D90348;
  font-size: 15px;
}

.single-alert-popup-contact-details-primary-input {
  display: flex;
}

.single-alert-popup-contact-details-primary-input {

}

.single-alert-popup-contact-details-contact-cell input[type="text"] {
  height: 25px;
  min-width: 240px;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-appearance: none;
  outline: none;
}

.single-alert-popup-contact-details-contact-cell input:disabled {
  background-color: rgb(235, 235, 228);
  cursor: not-allowed;
}

.single-alert-popup-contact-details input.is-invalid-input {
  box-shadow: inset 0 0 0 1px #D90348 !important;
  -webkit-box-shadow: inset 0 0 0 1px #D90348 !important;
  -webkit-appearance: none !important;
}

.single-alert-selector-modal{
  top: 40%
}

.single-alert-popup-contact-details-secondary-contact {
  grid-column: 1/7 !important;
}

.single-alert-popup-contact-details-secondary-contact .Collapsible {
  grid-column: 1/7;
}

.single-alert-popup-trigger-wrapper h3 {
  color: #cb1d4b;
  font-weight: bold;
  margin-bottom: 0;
}

.single-alert-popup-trigger-wrapper svg {
  fill: #cb1d4b;
  margin-bottom: -4px;
}

.single-alert-popup-trigger-wrapper div {
  margin: 0 10px 0;
  display: inline-block;
}

.single-alert-successfully-saved-popup {
  text-align: center;
}

.single-alert-successfully-saved-popup p {
  margin: 20px 0 20px;
}

.container-table__status-cell_all-status-table {
  width: 100%;
}

.container-table__status-cell_all-status-table td {
  padding: 0;
  font-weight: 400;
}

.container-table__status-cell_all-status-table tr td:first-child {
  width: 1%;
  white-space: nowrap;
  padding-right: 15px;
}

.container-table__status-cell_all-status-table tbody, .container-table__status-cell_all-status-table td{
  border-top: none !important;
}

.container-table__status-cell_all-status-table-current-status,
.container-table__status-cell_all-status-table-current-status td{
  color: rgb(0, 128, 0);
  vertical-align: text-top;
}

.container-table__status-cell_all-status-table-current-status td > div {
  white-space: normal;
}

.container-table__status-cell_all-status-table-past-status {
  color: #003865;
}

.container-table__status-cell_all-status-table-past-status,
.container-table__status-cell_all-status-table-past-status td {
  color: rgb(128, 128, 128);
}

.current-status-on-hold,
.current-status-on-hold td {
  color: rgb(217, 3, 72) !important;
}

.current-status-on-hold-processed,
.current-status-on-hold-processed td {
  color: #fa5b11 !important;

}

.container-table-remove-containers-from-list-controls {
  justify-content: flex-end;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}

.container-table-remove-containers-from-list-controls span {
  line-height: normal;
  margin: 0 5px 0;
}

.container-table-remove-containers-from-list-controls button {
  height: 100%;
  flex: 1;
  white-space: normal;
  padding: 0 15px;
  border: none;
}

.container-table-add-containers-to-list-controls {
  float: right;
  margin-top: 10px;
}

.container-table-add-containers-to-list textarea {
  min-height: 100px;
  border: 1.1px solid #a9a9a9;
}

.remove-containers-modal {
  top: 3%;
  transform: translate(-50%, 0);
}

.info-apply-template-remove-container {
  display: inline-flex;
  color: white;
  border-color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
}

.modal-container-list-first-column {
  width: 50%;
  display: inline-block;
}

.modal-container-list-second-column {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.container-table__status-cell_all-status-table-grayed-out,
.container-table__status-cell_all-status-table-grayed-out td {
  color: rgb(128, 128, 128) !important;
}

.single-alert-popup > .form-error {
  margin: 15px 0 15px;
}

.single-alert-popup > .form-error > span {
  color: #D90348 !important;
  text-decoration: underline;
  cursor: pointer;
}

.container-table__status-cell_advanced-mode-alert-header {
  padding-left: 10vw;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  float: left;
}

.container-table__status-cell_advanced-mode-alert-header span:first-child {
  width: 100%;
  display: block;
}

.container-table__status-cell_advanced-mode-alert-header span:nth-child(2) {
  text-align: left;
  padding-right: 20px;
}

.container-table__status-cell_advanced-mode-alert-header span:nth-child(n+2) {
  display: inline-block;
}

.container-table__status-cell_all-status-table-alert-cell {
  min-width: 150px;
  width: 150px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  float: right;
}

.container-table__status-cell_advanced-view_alert-info span,
.container-table__status-cell_all-status-table-alert-cell span {
  display: inline-block;
  width: 50%;
}

.container-table__status-cell_gray-alert-cell {
  color: rgb(128, 128, 128) !important;
}

.container-table__status-cell_blue-alert-cell {
  color: #003865 !important;
}

.container-table__status-cell_advanced-view_alert-info {
  display: inline-block;
  width: 85px;
  float: right;
  text-align: center;
}

.container-table_email-sms-alerts-suspended {
  text-align: right;
  width: 100%;
  display: inline-block;
  color: #D90348;
  margin-bottom: 5px;
}

.single-alret-popup-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.single-alert-popup-secondary-contact{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.single-alert-popup-secondary-contact div{
  flex: 1
}
.single-alert-popup-secondary-contact input[type="text"]{
 min-width: 70px;
 width: 100px
}

.Export .activity_alert_template{
  border: 1px solid #D90348
}

.Return .activity_alert_template{
  border: 1px solid #173A64
}

.Import .activity_alert_template{
  border: 1px solid #36B4E5
}

.Transhipment .activity_alert_template{
  border: 1px solid #FFB81D
}

.single-alert-popup-contact-details .Collapsible{
  margin-bottom: 40px
}

.single-alert-popup-alert-template-wrapper{
  box-sizing: border-box;
  width: 400px;
}
.single-alert-popup-alert-template-wrapper .activity_alert_template{
  margin: 0;
}

.single-alert-popup-alert-template-wrapper td{
  padding:5px
}