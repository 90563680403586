@supports (-ms-ime-align:auto) {
  .page_id_saved-lists {
    display: flex !important;
    flex-flow: column nowrap !important;
  }
  .page_id_saved-lists-content > form {
    align-self: center;
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_saved-lists-content,
  .page_id_saved-lists {
    display: flex !important;
    flex-flow: column nowrap !important;
  }

  .page_id_saved-lists-content > form {
    align-self: center;
  }

  .saved-lists-controls,
  .saved-lists-search-area div {
    width: 800px !important;
  }
}

.page_id_saved-lists-header > h2{
  color: #36B4E5;
  font-size: 34px;
  margin: 0 0 10px 0;
}

.page_id_saved-lists-header > h4 {
  font-size: 18px;
  margin-top: 30px;
}

.page_id_saved-lists-header {
  grid-column: 1/13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
}

.saved-lists-table-of-instructions > tbody > tr {
  line-height: 30px;
}


.saved-lists-table-of-instructions > tbody > tr > td:nth-child(2){
  text-indent: 20px;
}

.page_id_saved-lists-header h4{
  grid-column: 1/13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  margin-top: 0;
}

.page_id_saved-lists-header h4,
.saved-lists-table-of-instructions {
  color: #173a64;
}

.saved-lists-table-of-instructions > tr > td:first-child {
  padding-right: 5vw;
}

.saved-lists-name select {
  height: 34px;
  width: 380px;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  outline: none;
  color: #173a64;
  background: #fff;
  font-size: 14px;
  margin: 7vh 0 7vh;
}

.page_id_saved-lists-content > form {
  grid-column: 1/13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  -ms-grid-row: 2;
  justify-self: center;
  min-width: 800px;
}

.new-saved-list-content,
.current-saved-list-content {
  padding: 10px;
  width: 100%;
  min-height: 120px;
  height: 100%;
  box-sizing: border-box;
}

.edit-saved-list-modal textarea,
.current-saved-list-content {
  font-family: monospace !important;
}

.new-saved-list-content,
.current-saved-list-content {
  font-size: 18px;
}

.saved-lists-search-area {
  display: flex;
  flex-flow: row nowrap;
  margin: 20px 0 20px;
  align-items: center;
}

.saved-lists-search-area div {
  flex-grow: 3;
  width: 700px;
  margin-right: 50px;
}

.saved-lists-controls {
  display: flex;
  width: 700px;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-sizing: border-box;
}

.saved-lists-name select:disabled {
  background-color: rgb(235, 235, 228);
}

.form-error {
  color: #D90348;
  font-size: 12px;
}

.edit-saved-list-modal-controls,
.create-a-new-list-modal-controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.edit-saved-list-modal input,
.create-a-new-list-modal input {
  height: 34px;
  width: 50%;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-appearance: none;
  outline: none;
  background: #fff;
}

.edit-saved-list-modal textarea,
.create-a-new-list-modal textarea {
  margin: 10px 0 0;
  box-sizing: border-box;
}

.edit-saved-list-modal > form > div:nth-child(2),
.create-a-new-list-modal > form > div:nth-child(2) {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 10px;
}

.page_id_saved-lists-content {
  background-color: white;
  display: grid;
  padding: 20px;
}