@media all and (max-width: 499px) {

  .page_id_alert_templates {
    padding-bottom: 150px;
  }

  .Collapsible{
    width: 100%;
  }

  .activity_alert_template-header{
    margin-left: 0 
  }


  .collapsible-content {
    background-color: white;
    padding: 5px;

  }

  .alert-template-mobile-row {
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    color: #173A64;
  }

  .alert-template-status-name {
    display: inline-block;
    padding: 5px 0 5px;
    grid-column: 1;
  }

  .alert-template-value-cell {
    display: inline-block;
    margin: auto;
  }

  .alert-template-value-cell input:enabled {
    outline: 1px solid #173A64;
    outline-offset: -2px;
  }

  .activity_alert_template {
    width: 100%;
    padding: 0;

  }
  .alert-templates-collapsible-trigger-wrapper{
    padding: 7px 7px 7px 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .alert-templates-collapsible-trigger {
    display: inline-block;
    font-size: 14px;
    color: white;
    font-weight: bold;
  }

  .activity_alert_template svg {
    float: right;
    fill: #fff;
    margin: -5px 5px 0 0;
  }

  .Collapsible__trigger{
    margin-bottom: 0;
  }
}