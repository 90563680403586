@media screen and (max-width: 499px) {
  .page_id_history {
    grid-column: 1/27;
    padding: 20px 20px 300px 20px;
  }

  .history-button-container {
    display: grid;
    margin-top: 15px;
    margin-right: 0;
  }

  .history-clear-all-button {
    grid-column: 1/1;
    text-align: left;
  }

  .history-search-button {
    grid-column: 16/16;

  }

  .history-search-filter-collapsible-trigger {
    display: inline-block;
    float: left;
    padding: 5px;
  }

  .extra-filters-wrapper {
    border: 1px solid #173a64;
    background: white;
  }

  .history-search-filter-collapsible-trigger-wrapper {
    display: inline-block;
    text-align: left;
    margin-left: 5px;
    width: 100%;
  }

  .history-search-filter-collapsible-trigger-wrapper img {
    display: inline-block;
    float: right;
    padding-right: 5px;
  }

  .history-search-area p {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .history-search-area .journey_date{
    display: block;
    text-align: left;
  }

  .history-search-header-instructions {
    font-size: 14px;
  }

  .history-search-area {
    height: auto;
  }

  .history-search-container-numbers-area h2 {
    margin: 0 0 10px 0 ;
    color: #FFB81D;
  }

  .page_id_history > h2 {
    font-size: 26px !important;
    margin-bottom: 20px;
  }

  .history-search-container-numbers-area textarea{
    height: 20vh;
  }

  .history-search-form{
    flex-direction: column;
  }
  .history-search-form-wrapper,  .history-search-to-wrapper{
    display: block;
    margin: 0;
  }

  .history-search-form-wrapper > div:first-child, .history-search-to-wrapper > div:first-child {
    width: 100%;
  }

  .history-search-filters-container {
    width: 100%;
  }

  .history-search-filters-container h3 {
    margin: 10px 0 10px;
  }

  .history-search-date-selection{
    width: 100%;
    margin-left: 0;
    display: inline-block;
  }

  .history-search-date--mobile-selection {
    width: 50%;
    display: inline-block;
    margin-bottom: 15px;
    text-align: left;
  }

  .history-datepicker-wrapper{
    display: flex;
  }

  .history-date-from-wrapper, .history-date-to-wrapper{
    flex-flow: row wrap;
  }

  .history-datepicker-wrapper div:nth-child(1){
    flex-grow: 10;
    flex-basis: 65%;
  }

  .history-datepicker-wrapper span:last-child {
    flex-basis: 100%;
  }

  .history-search-date-selection > .history-search-date--mobile-selection:first-child > div {
    padding: 0 5px 0 0;
  }

  .history-search-date-selection > .history-search-date--mobile-selection:last-child > div {
    padding: 0 0 0 5px;
  }

  .history-datepicker-icon{
    margin-bottom: 0;
  }

  .container-history-table_search-mode-container {
    flex-flow: column nowrap;
    margin-bottom: 0;
  }

  .container-history-table_search-mode-option-description {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .container-history-table_search-mode-option {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin: 0;
  }

  .mode-option-description-selected {
    border: none;
  }

  .history-search-mode-collapsible-trigger-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5px;
    width: 100%;
  }
  .history-search-mode-collapsible-trigger-wrapper img {
    margin-top: -5px;
    align-self: center;
  }
  .container-history-table_search-mode-option-description .Collapsible__contentOuter {
    margin: 10px 0 15px;
  }

  .specific-container-journey-date-selection {
    text-align: center;
  }

  .container-history-table_search-mode-option-description .Collapsible__contentInner {
    font-size: 14px;
  }

  .extra-filters-wrapper .Collapsible__contentInner {
    margin: 20px;
  }

  .port-activity-search-area {
    width: unset;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    text-align: left;
    align-self: center;
    margin-left: unset;
  }

  .port-activity-search-area p {
    font-weight: bold;
  }

  .history-search_search-date-and-filters-wrapper > div:first-of-type {
    width: 100%;
    margin: 0;
  }

  .history-search_search-date-and-filters-wrapper {
    flex-flow: column nowrap;
    width: 100%;
  }
  .page_id_history-textarea-column, .page_id_history-date-column{
    min-width: 100px; 
  }
}