@media all and  (max-width: 499px) {
  .security-questions {
    margin-top: 0px;
  }

  .password-reset {
    grid-row-gap: 1px;
  }

  .password-reset > h4 {
    -ms-grid-column-span: 1;
    margin-bottom: 0px;
    margin-top: 5px;
    color: #D90348;
    font-size: 25px;
  }

  .password-reset > p {
    grid-row: 0 /9;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }

  .password-reset form {
    grid-row: 6 / 9;
  }

  .password-reset form > label:nth-child(1) {
    width: 185px;
    display: block;
  }

  .password-reset form > div > div > label:nth-child(1) {
    width: 236px;
    display: block;
    grid-row: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-column: 1 / 6;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
  }

  .password-reset form > button:nth-child(3) {
    margin-right: 114px;
    grid-row: 3 / 5;
    grid-column: 1 / auto;
  }

  .password-reset .security-questions > div:nth-child(1) {
    margin-top: 38px;
  }

  .security-questions div > input {
    width: 233px;
    margin-top: 10px;
  }

  .password-reset .security-questions .security-questions__button_send {
    margin-left: 20px;
    margin-top: 10px;
    grid-column: 1/13;
    padding-bottom: 0px;
  }

  .temp-password-attempts-exceeded-popup h2 {
    padding: 0 !important;
    font-size: 17px;
    line-height: 25px !important;
  }
}
