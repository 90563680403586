.app-modal .email-confirmation h4{
  color: #173a64;
  text-align: center;
  padding: 50px;
}

.app-modal .email-confirmation {
  text-align: center;
}

.ReactModalPortal:first-of-type .ReactModal__Overlay  {
  z-index:10002;
}

.ReactModal__Overlay{
  display: flex;
  justify-content: center;
  align-items: center;
}