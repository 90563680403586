.collapsible-header-trigger {
  display: inline-block;
  font-weight: bold;
  font-size: 34px;
  color: #36B4E5;
}

.collapsible-header-trigger-wrapper svg {
  fill: #36B4E5;
  margin: 0px 0px -2px 10px;
  transform: scale(1.2);
}