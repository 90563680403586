@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  react-app {
    display: flex;
    min-height: 100%;
    height: 100%;
    flex-flow: column;
  }

  react-app > div:first-child {
    height: 100%;
    flex-grow: 2;
  }

  div.main_wrap > h2, .main_wrap-header > h2 {
    margin-bottom: 20px !important;
  }

  .page_id_containers {
    overflow-y: scroll;
    position: relative;
    min-height: 70vh;
  }

  nav {
    height: inherit;
  }
}

@supports (-ms-ime-align:auto) {
  div.policy-page {
    -ms-grid-row: 1 !important;
  }
}

react-app {
  display: grid;
  grid-template-columns: 63px 100px 1fr;
  grid-template-rows: 80px 1fr;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 80px 1fr;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-image: url("./../../static/images/login_page_background.png");
}



nav {
  padding-bottom: 150px;
}

div.main_wrap > h2,
.main_wrap-header > h2 {
  grid-column-start: 1;
  grid-column-end: 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 13;
  color: #36B4E5;
  font-size: 34px;
  margin: 0;
}

.app-modal {
  background: #fff;
  border: 5px solid;
  color: #173A64;
  padding: 40px 50px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 800px;
  max-width: 1000px;
  outline: none;
  font-size: 16px;
  line-height: 25px;
}

.ReactModal__Overlay {
  z-index: 10001;
}

.app-modal h4 {
  font-size: 35px;
  line-height: 40px;
  margin: 10px 0 0;
  color: #173A64;
}

.app-modal .button + .button {
  margin-left: 20px;
}

.app-modal__type_warning {
  color: #D90348;
}

#close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #173A64;
}

.info {
  width: 25px;
  height: 25px;
  border: 2px solid #173a64;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

a.phone-number-clickable-link {
  color: #173A64;
}

div.policy-page {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 30px 30px 180px 30px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 27;
  -ms-grid-column-span: 12;
  grid-template-rows: fit-content(200px);
  -ms-grid-row: 2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.break-word-text {
  word-break: break-all;
}

react-app select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}

textarea {
  border: 1.1px solid rgb(169, 169, 169);
}