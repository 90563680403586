div.advanced_feature_page_id {
  color: #173A64;
  background: #F0EEEE;
}

div.advanced_feature_header > h2 {
  color: #36B4E5;
  font-size: 34px;
  margin-top: 0;
  margin-bottom: 0;
}

div.advanced_feature_header, div.advanced_feature_content {
  grid-column: 1/12;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
}

div.advanced_feature_content {
  -ms-grid-row: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

div.advanced_feature_content > div:first-of-type {
  margin-right: 15px;
  flex: 1 1 50%;
}

div.advanced_feature_content > div:nth-of-type(2) {
  margin-left: 15px;
  flex: 1 1 50%;
}

div.advanced_feature_content section, .advanced_feature-video-wrapper {
  margin-bottom: 30px;
}

div.advanced_feature-video-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

div.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

div.back-to-account-div {
  text-align: center;
}

.advanced_feature_important_content {
  font-size: 24px;
}

.back-to-account-div button {
  line-height: 25px;
  height: auto;
}

.advanced_feature_page_id .frame-section_title > svg {
  fill: #ffffff;
  float: right;
  margin-top: -4px;
}

.advanced-feature-page-back-button-wrapper > button> span {
  display: inline-block;
}

.advanced-feature-page-back-button-wrapper > button > svg {
  height: 100%;
  top: unset;
}

.advanced_feature_header > h3 {
  font-size: 24px !important;
}