@media screen and (max-width: 499px) {

  .footer__container{
    flex-direction: column;
    height: auto !important;
    margin-top: -279px;
  }

  .footer__helpdesk{
    order: -1;
    padding: 15px
  }
  .footer__contact-info{
    padding: 25px;
    padding-top: 0 
  }

  .footer__links{
    padding: 10px;
    padding-top: 0 ;
    padding-bottom: 0 ;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .footer__links div{
    text-align: center;
    min-width: 120px; 
  }

  .footer__links_row{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

  }
  .contact-us__form-item{
    flex-direction: column;
  }
  .contact-us__form-item div{
    width: 100%;
  }
}

@media screen and (max-width: 340px){
  .footer {
    font-size: 8pt;
  }
}