.page_id_account section:nth-child(2) > form input.is-invalid-input {
  box-shadow: inset 0 0 0 1px #D90348;
  -webkit-box-shadow: inset 0 0 0 1px #D90348;
  -webkit-appearance: none;
  border: none;
}

.page_id_account form > div .form-error {
  color: #D90348;
  font-size: 12px;
}
