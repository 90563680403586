@media screen and (max-width: 499px) {

  .page_id_containers h2 {
    font-size: 26px !important;
    padding: 10px 0 20px;
  }

  .page_id_containers-found-containers-amount {
    font-size: 17px;
  }

  .container-card_container-info {
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-column: 1/4;
  }

  .container-card_container-info-advanced {
    display: grid;
    grid-template-columns: 70px 1fr 50px;
    grid-column: 1/4;
  }

  .container-card_container-info_title {
    display: inline-block;
    grid-column: 1;
  }

  .container-card_container-info_value {
    display: inline-block;
    grid-column: 2;
    white-space: pre-line;
  }

  .container-card {
    padding: 10px;
    border: 1px solid #FFB81D;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    color: #003865;
    display: grid;
    grid-template-columns: 25px 1fr 110px;
  }

  .page_id_containers {
    grid-column: 1/27;
  }

  .container-card_container-number {
    grid-column: 1/3;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .container-card_container-number-advanced {
    grid-column: 2/3;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .container-card_container-info_advanced-status-details {
    grid-column: 1/4;
  }

  .container-card_container-info_status-details {
    grid-column: 2/4;
  }

  .container-card_container-info_status-icon, .container-card_container-info_status-details, .container-card_container-info_advanced-status-details {
    font-weight: normal;
    margin: 20px 0 5px;
  }

  .container-table__actions {
    grid-column: 2/3;
    margin-right: 0;
    margin-top: 10px;
    position: relative;
    top: 0;
    right: 0;
  }

  .container-table_card-toolbar-advanced button, .container-table_card-toolbar button{
    font-size: 12px;
  }

  .container-table_card-toolbar {
    display: inline-block;
    width: 100%;
    right: 0;
    margin-bottom: 15px;
  }

  .container-table_card-toolbar button:nth-child(even){
    float: right;
  }

  .container-table__card-sorting-menu {
    margin: 0;
    margin-top: 35px;
    width: 100%;
    padding: 0;
    list-style: none;
    background: #D2D1D1;
    position: absolute;
    z-index: 1;
    display: none;
  }

  .container-table__actions_active .container-table__card-sorting-menu {
    display: block;
  }

  .container-table__card-sorting-menu li {
    white-space: nowrap;
    padding: 5px 23px;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
  }

  .table__legend {
    font-size: 12px;
    width: 100%;
  }

  .changed-container-card {
    background-color: rgba(58, 181, 230, 0.3);
  }



  .container-table-toolbar-item {
    margin: 0;
  }

  .container-card_container-actions button{
    width: inherit;
  }

  .single-alert-popup-contact-details-contact-cell {
    display: block;
  }

  .single-alert-popup-contact-details-contact-cell input[type="text"]{
    min-width: auto;
  }

  .single-alert-popup-contact-details h3 {
    grid-column: 1/8;
  }

  .single-alert-popup-contact-details div:nth-child(2) {
    grid-column: 1/8;
    margin-bottom: 7px;
  }

  .single-alert-popup-contact-details div:nth-child(3) {
    grid-column: 1/8 !important;
  }

  .single-alert-popup-contact-details-contact-cell label {
    width: 70px;
  }

  .single-alert-popup-alert-template-wrapper {
    border: 2px solid #173A64;
  }

  .single-alert-selector-modal{
    top: 0;
    transform: translate(-50%, 0);
  }

  .single-alert-popup-header {
    margin-top: 40px;
  }

  .single-alert-popup-header h2 {
    font-size: 20px;
  }

  .single-alert-popup-header h3 {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    margin: 10px 0 10px;
  }

  .single-alert-popup-contact-detail-controls {
    width: 100%;
    text-align: center;
  }

  .single-alert-popup-contact-details-primary-input{
    width: 100%;
  }
  .single-alert-popup-contact-details-contact-cell div:nth-child(1),
  .single-alert-popup-contact-details-contact-cell input[type="text"] {
    width: 100%;
  }

  .single-alert-popup-contact-details-secondary-contact div {
    width: 100%;
  }

  .single-alert-popup-trigger-wrapper h3 {
    display: flex;
    margin-right: 0;
  }

  .single-alert-popup-contact-details-secondary-contact h3 > p {
    padding: 0 !important;
  }

  .single-alert-popup-trigger-wrapper h3 div {
    width: 20px;
  }

  .single-alert-popup-contact-details-primary-contact div:nth-child(3){
     margin-left: 0;
  }

  .single-alert-popup-contact-details-primary-contact h3 {
    margin-right: 0;
  }

  .single-alert-successfully-saved-popup {
    padding-top: 10px;
  }

  .single-alert-successfully-saved-popup p {
    margin: 15px;
  }

  .single-alert-successfully-saved-modal {
    top: 0;
    transform: translate(-50%, 0);
  }

  .container-card_container-info_advanced-view_status-icon {
    grid-column: 3/4;
    grid-row: 1/4;
    margin-top: 15px;
  }

  .container-card_container-info_status-icon {
    grid-column: 1;;
    margin-top: 15px;
  }

  .container-card_advanced-view_container-statuses-container > div:nth-child(even){
    background-color: #d6e6eb;
  }

  .container-table-remove-containers-from-list-controls {
    justify-content: space-around;
  }

  .container-table-remove-containers-from-list-controls button {
    line-height: 20px;
    padding: 5px;
  }

  .container-table__card-toolbar-first-line-controls {
    display: flex;
    justify-content: space-between;
  }

  .container-table__card-toolbar-second-line-controls {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
  }

  .container-table_card-toolbar > button,
  .container-table_card-toolbar > .container-table__actions,
  .container-table__card-toolbar-second-line-controls button,
  .container-table__card-toolbar-first-line-controls button {
    width: 35vw;
    line-height: normal;
    white-space: normal;
  }

  .container-table_card-toolbar > .container-table__actions > button {
    width: 35vw !important;
  }

  .container-table__card-toolbar-second-line-controls button:nth-child(1) {
    float: left;
  }

  .container-table__card-toolbar-second-line-controls button:nth-child(2) {
    float: right;
  }

  .app-modal-header {
    margin: 30px 0 15px;
  }

  .info-apply-template-remove-container {
    display: inline-flex;
    color: #173A64;
    border-color: #173A64;
    width: 15px;
    height: 15px;
    font-size: 12px;
    margin: auto;
  }

  .remove-containers-from-list-second-column {
    display: block;
  }

  .single-alert-popup > .form-error {
    margin: 0 0 5px 0;
  }

  .container-table__status-cell_advanced-view_additional-info,
  .container-table__status-cell_advanced-view_status-value {
    display: inline-block;
    width: calc(100% - 86px);
  }

  .container-table__status-cell_advanced-mode-alert-header{
    color: #003865;
    float: right;
    font-size: 12px;
    padding: 0;
    display: block;
  }

  .container-table__status-cell_advanced-mode-column-header {
    width: 100%;
    display: inline-block;
  }
  .container-table__sortby-button{
    margin:0;
    width: auto
  }
}