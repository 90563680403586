@supports (-ms-ime-align:auto) {
  .privacy_policy_main_wrap section {
    margin: 70px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .privacy_policy_main_wrap section {
    margin: 70px 0 0 0;
  }

  .page_privacy_policy{
    overflow-y: scroll;
  }

  .ie_gap{
    height: 100px;
  }
}

.logged-in-layout__navbar + div.page_privacy_policy {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
  -ms-grid-row: 1;
}

.logged-in-layout__navbar_collapsed + div.page_privacy_policy {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
  -ms-grid-row: 1;
}

div.policy-page {
  padding: 30px 30px 180px 30px;
}

.page_privacy_policy > h1 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
  background-color: #36b4e5;
  margin: 0;
  padding: 10px 0 10px 40px;
  color: #fff;
  text-align: center;
}

.page_privacy_policy section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;

  text-align:justify;
}

.page_privacy_policy legend {
  font-weight: bold;
  padding:40px 0 0 30px;
}

.page_privacy_policy form>div {
  clear: both;
  text-align: right;
  padding: 10px 20px;
}

.visibleDiv{
}

.invisibleDiv{
  display: none;
}

.page_privacy_policy ol.withoutPoint {
  counter-reset: item;
  list-style-type: none;
}

.page_privacy_policy li.withoutPoint {
  display: block;
}

.page_privacy_policy li.withoutPoint:before {
  content: counter(item) " ";
  counter-increment: item;
}

.page_privacy_policy ol.withPointAndNextNumber {
  counter-reset: item;
  padding: 0;
  margin-left: 0px;
}
.page_privacy_policy li.withPointAndNextNumber {
  margin-bottom: 3px;
  display: block
}
.page_privacy_policy li.withPointAndNextNumber:before {
  content: counters(item, ".") " ";
  counter-increment: item
}

.page_privacy_policy ol.alphaWithBracket {
  counter-reset: list;
  margin: 0;
}

.page_privacy_policy li.alphaWithBracket {
  list-style: none;
  position: relative;
}

.page_privacy_policy li.alphaWithBracket:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
}

.page_privacy_policy h3.marginMainPoints{
  margin-top: 30px;
  margin-bottom: -10px;
}

.page_privacy_policy a.email{
  text-decoration: none;
  color: #36B4E5;
}

.page_privacy_policy a.site{
  text-decoration: underline;
  color: #36B4E5;
}

.page_privacy_policy div.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_privacy_policy button {
  margin: 40px 0px;
  text-align: center;
}

.page_privacy_policy table {
  width: 100%;
  border-collapse: collapse;
  border: 7px solid white;
}

.page_privacy_policy td, th {
  padding: 10px;
  border: 1px solid;
}

.page_privacy_policy td.category{
  text-align:left;
}