.page_id_containers{
  background: #F0EEEE;
}

.page_id_containers > .container-table-wrapper {
  -ms-grid-row: 2;
}

.container-table-wrapper > table{
	grid-column-start: 1;
  -ms-grid-column: 1;
	grid-column-end: 13;
  -ms-grid-column-span: 12;
  width: 100%;
  max-height: 30px;
}

.page_id_containers > section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 11;
  -ms-grid-column-span: 10;
  width: 100%;
  max-height: 30px;
}

.page_id_containers > section {
	text-align: right;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: space-between;
  -ms-grid-row: 3;
}

.page_id_containers > section > div > .button+.button {
	margin-left: 20px;
}

.table__legend {
  width: auto;
}

.table__legend p {
  color: #334D70;
  width: 225px;
  text-align: left;
  margin: 5px;
}

.table__legend > div {
  display: flex;
}

.table__legend > div > p {
  font-weight: bold;
}

.table__legend p:nth-child(2) {

}

.spinner-container{
  grid-column: 6;
  -ms-grid-column: 6;
}

.page_id_containers-found-containers-amount {
  display: inline;
  font-size: 20px;
  margin-left: 20px;
  align-self: center;
  font-weight: bold;
}

.info-containers-amount {
  display: inline-flex;
  position: relative;
  font-size: 10px;
  vertical-align: top;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-top: 15px;
}

.container-amount-hint-wrapper .react-hint {
  min-width: 300px;
}

.apply-template-remove-container-hint-wrapper .react-hint {
  width: 250px;
}

.info-containers-amount.info {
  border: 2px solid #36B4E5;
}

.react-hint__content {
  background-color: #173A64 !important;
}

.container-page_critical_alert_wrapper {
  grid-column: 1/12;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
}

.main_wrap-header {
  grid-column: 1/12;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
}


.custom-hint {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: .5s fadeIn;
  width: 150px;
  text-align: center
}

.custom-hint__content {
	padding: 10px;
	border-radius: 5px;
	background: #173A64;
	color: #fff;
}

.custom-hint:after {
	content: '';
	width: 0;
	height: 0;
	margin: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 5px solid transparent;
}

.custom-hint--top:after {
	top: auto;
	border-bottom: none;
	border-top-color: #000;
}

.custom-hint--left:after {
	left: auto;
	border-right: none;
	border-left-color: #000;
}

.custom-hint--right:after {
	right: auto;
	border-left: none;
	border-right-color: #000;
}

.custom-hint--bottom:after {
	bottom: auto;
	border-top: none;
	border-bottom-color: #000;
}


.custom-hint-small {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: .5s fadeIn;
  width: 90px;
  text-align: center
}

.custom-hint-small__content {
	padding: 10px;
	border-radius: 5px;
	background: #173A64;
	color: #fff;
}

.custom-hint-small:after {
	content: '';
	width: 0;
	height: 0;
	margin: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 5px solid transparent;
}

.custom-hint-small--top:after {
	top: auto;
	border-bottom: none;
	border-top-color: #000;
}

.custom-hint-small--left:after {
	left: auto;
	border-right: none;
	border-left-color: #000;
}

.custom-hint-small--right:after {
	right: auto;
	border-left: none;
	border-right-color: #000;
}

.custom-hint-small--bottom:after {
	bottom: auto;
	border-top: none;
	border-bottom-color: #000;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
