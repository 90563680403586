@media screen and (max-width: 499px) {
  .page_privacy_policy td.category {
    min-width: 80px;
  }
  table.privacy-policy-table-7 td:nth-of-type(1):before {
    content: "Categories of Organisation";
  }

  table.privacy-policy-table-7 td:nth-of-type(2):before {
    content: "Purpose";
  }

  table.privacy-policy-table-7 td:nth-of-type(3):before {
    content: "Location";
  }

  table.privacy-policy-table-7 td:nth-of-type(4):before {
    content: "Categories of Organisation";
  }

  table.privacy-policy-table-7 td:nth-of-type(5):before {
    content: "Purpose";
  }

  table.privacy-policy-table-7 td:nth-of-type(6):before {
    content: "Location";
  }
}