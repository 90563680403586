svg{
  fill: #ffffff
}

.mainframe {
  padding: 30px;
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 27;
  -ms-grid-column: 2;
  -ms-grid-column-span: 25;
  box-sizing: border-box;
  padding-bottom: 180px;
  overflow: auto;
  color: #173a64;
  background: #F0EEEE;;
}
.header {
  margin-top: 5px;
  margin-bottom: 20px; 
  margin-left: 10px;
  color: #36b4e5;
  font-size: 34px;
}
.flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 88%;
}
.col {
  flex: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rightCol{
  padding-left: 15px 
}
.frame {
  margin: 10px;
}
.info{
  background: white;
  padding: 15px
}

.leftFrameContent {
  padding: 20px;
}
.addinfo {
  padding: 12px;
  background: white;
  .formRow{
    margin-bottom: 10px;
  }
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 15px;
  }
}
.button {
  box-sizing: border-box;
  font-size: 20px;
  min-width: 100px;
  height: auto;
}

.formRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  margin-top: 10px;
  color: #173a64;
  span{
    min-width: 200px;
    flex: 1;
    word-wrap: break-word;
  }
  input{
    flex: 1;
  }
  div{
    flex: 1;
  }
}
.formName{
  font-weight: 600;
}
.buttonRow{
  display: flex;
  justify-content: space-between;
}

.input {
  border: 1px solid #D2D1D1;
  padding-left: 7px;
  height: 35px;
  box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  background: white
}

.logo{
  height: 45px;
}

.checkboxContainer{
  font-size: 14px;
  display: flex;
  padding-top: 50px
}

@media screen and (max-width: 499px) {
  .mainframe {
    padding: 20px;
    padding-bottom: 300px;
    grid-column-start: 1;
    height: auto;
    font-size: 15px;
  }
  .flex {
    display: inline-block;
  }
  .frame {
    margin: 0px;
    margin-bottom: 10px;
  }
  .header {
    font-size: 30px;
    margin-left: 0;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    button {
      margin-left: 0;
    }
  }
  .col {
    height: auto;
    flex: 0px;
  }
  .rightCol{
    padding-top: 0px;
    padding-left: 0; 
  }
  .leftFrame{
    height: auto;
  }
  .leftFrameContent {
    padding: 0;
  }
  .buttonRow{
    flex-direction: column
    
  }
  .formRow {
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #D2D1D1;
    span{
      margin-top: 5px;
      width: 100%;
      word-break: break-all;
    }
    div{
      width: 100%;
    }
    input{
      flex: auto
    }
  }
  .checkboxContainer{
    padding-top: 0px
  }
}
