@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .security-questions{
    margin-top: 20px;
  }
}

@supports (-ms-ime-align:auto) {
  .security-questions{
    margin-top: 20px;
  }
}

.password-reset {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  color: #173a64;
}

.password-reset > h4 {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
}

.password-reset > p {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-row: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}

.password-reset form {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-row: 3 / 4;
  -ms-grid-row: 3;
  -ms-grid-row-span: 4;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 10px;
  color: #173a64;
}

.password-reset form > label:nth-child(1) {
  grid-row: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-column: 1 / 6;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
}

.password-reset form > div:nth-child(2) {
  grid-row: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-column: 1 / 9;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
}

.password-reset form > button:nth-child(3) {
  grid-row: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
  grid-column: 10 / auto;
  -ms-grid-column: 6;
  -ms-grid-column-span: 2;
  color: #173a64;
}

.password-reset input {
  height: 34px;
  width: 100%;
  box-sizing: border-box;
  text-indent: 10px;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-appearance: none;
  outline: none;
  background: #fff;
  border: none;
}

.password-reset form > div input.is-invalid-input {
  box-shadow: inset 0 0 0 1px #D90348;
  -webkit-box-shadow: inset 0 0 0 1px #D90348;
  -webkit-appearance: none;
  border: none;
}

.password-reset form > div .form-error.is-visible {
  color: #D90348;
  font-size: 12px;
}

.password-reset .security-questions {
  grid-row: 3 / auto;
  -ms-grid-row: 3;
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 8;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 11fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
}

.password-reset .security-questions > div:nth-child(1) {
  grid-column: 1 / 9;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  box-sizing: border-box;
}

.password-reset .security-questions label + div {
  display: flex;
  flex-flow: column;
}

.password-reset .security-questions div > input {
  margin-top: 10px;

}

.password-reset .security-questions .security-questions__button_send {
  grid-column: 10/13;
  -ms-grid-row: 1;
  -ms-grid-column: 12;
  -ms-grid-column-span:2;
  display: flex;
  align-items: flex-end;
  align-self: end;
}

div.password-reset-error {
  grid-column: 1 / 11;
  -ms-grid-column: 1;
  -ms-grid-column-span: 10;
  -ms-grid-row: 7;
  font-size: 13px;
  color: red;
  width: 100%;
}

.password-reset_success {
  text-align: center;
}
