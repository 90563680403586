@media all and  (max-width: 499px) {
  .delete-account h4{
    font-size: 20px;
    line-height: 30px;
  }

  .delete-account p, .delete-account h4{
    padding-left: 2%;
    padding-right: 2%;
  }

  .delete-account p:last-child{
    text-align: center;
  }

}