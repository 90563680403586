@media screen and (max-width: 499px) {
  div.page_id_account {
    grid-column: 1/27;
    padding-bottom: 300px;
  }

  div.page_id_account > h2 {
    padding: 20px 10px 10px 20px;
    font-size: 26px;
  }

  .page_id_account section:nth-child(2) > form input {
    height: 24px;
    width: 100%;
    text-indent: 10px;
  }

  .page_id_account section:nth-child(2) > form select {
    width: 100%;
    height: 26px;
    font-size: 15px;
    text-indent: 5px;
  }

  .page_id_account #edit_button {
    grid-column: 8 / auto;
  }

  .page_id_account > section {
    margin: 20px;
  }

  .page_id_account .frame-section_title > svg {
    fill: #ffffff;
    float: right;
    margin-top: -4px;
  }

  .page_id_account-package-details-section-content-table tr,
  .page_id_account-alert-preferences-section-content > div,
  .account-details-form > div,
  .access-information-form > div {
    border-bottom: 1px solid #d2d1d1;
  }

  .page_id_account-package-details-section-content-table tr:first-child,
  .page_id_account-package-details-section-content-table tr:last-child,
  .page_id_account-alert-preferences-section-content > div:first-child,
  .page_id_account-alert-preferences-section-content
    > div:nth-last-child(-n + 2),
  .account-details-form > div:nth-last-child(-n + 2),
  .access-information-form > div:nth-last-child(-n + 2) {
    border: none;
  }

  .account-details-form > div > label,
  .access-information-form > div > label {
    grid-column: 1/13;
    font-weight: bold;
  }

  .account-details-form > div > div,
  .access-information-form > div > div {
    grid-column: 1/13;
    text-indent: 0;
    height: auto;
  }

  .account-details-form input,
  .account-details-form select {
    width: 100%;
  }

  .account-details-form > div > div > div {
    text-indent: 0;
    height: auto;
  }

  .page_id_account-alert-preferences-section-content {
    color: #173a64;
  }

  .page_id_account-alert-preferences-section-content
    > div:nth-child(n + 2)
    > div:first-child {
    font-weight: bold;
  }

  .page_id_account-alert-preferences-section-content
    > div:nth-child(n + 2)
    > div:nth-child(2) {
    margin: 5px 0 5px;
  }

  .page_id_account-alert-preferences-section-content > div {
    display: block;
  }

  .page_id_account-package-details-section-content-table tr {
    display: inline-table;
    width: 100%;
    color: #173a64;
  }

  .page_id_account-package-details-section-content-table
    > tbody
    > tr:nth-child(2) {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .page_id_account-package-details-section-content-table
    > tbody
    > tr:nth-child(2)
    > td {
    padding: 0;
  }

  .page_id_account-package-details-section-content-table
    > tbody
    > tr:nth-child(2)
    > td:nth-child(2) {
    white-space: pre;
  }

  .my-account-trial-advanced-wrapper-free,
  .my-account-trial-advanced-wrapper-advanced,  .my-account-trial-advanced-wrapper-paid {
    text-align: center;
    margin-right: unset;
    padding: 0 20px 0 20px;
  }

  .my-account-trial-advanced-wrapper-free .button,
  .my-account-trial-advanced-wrapper-advanced .button {
    line-height: unset;
    height: 55px;
    width: 250px;
  }
  .my-account-trial-advanced-wrapper-free button {
    margin: 0;
    margin-top: 10px;
  }
  .my-account-trial-advanced-wrapper-advanced,   .my-account-trial-advanced-wrapper-paid{
    flex-flow: column nowrap;
  }
  .my-account-trial-advanced-wrapper-advanced button {
    margin: 0;
    margin-top: 10px;
  }

  .my-account-trial-advanced-wrapper-advanced .button,   .my-account-trial-advanced-wrapper-paid .button {
    align-self: center;
  }

  .button_color_yellow {
    padding: 5px 5px 7px 5px;
    white-space: pre-wrap;
    line-height: unset;
  }

  .advanced-features-section-header {
    display: inline-block;
    font-size: 22px;
  }
  .my-account-trial-advanced-wrapper-paid {
    display: flex;
    flex-direction: row;
    justify-content: space-between
  }
  .changed-activity-modal-controls .button{
    margin-top:10px;
  }
  .page_id_account-cancel-popup-buttons{
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .page_id_account-cancel-popup-buttons .button{
    height: auto;
    line-height: initial;
  }
}
