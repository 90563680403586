@supports (-ms-ime-align:auto) {
  .change-password > form > div:nth-child(1),
  .change-password > form > div:nth-child(2),
  .change-password > form > div:nth-child(3),
  .change-password > form > div:nth-child(4) {
    margin-right: 10px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .change-password > form > div:nth-child(1),
  .change-password > form > div:nth-child(2),
  .change-password > form > div:nth-child(3),
  .change-password > form > div:nth-child(4) {
    margin-right: 10px;
  }
}

.change-password {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  color: #173a64;
}

.change-password > h4 {
  grid-column: 1 / 13;
  grid-row: 1 / 2;
}

.change-password > div {
  grid-column: 1 / 13;
  grid-row: 2 / 3;
}

.change-password > form {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  color: #173a64;
}

.change-password > form > div:nth-child(1) {
  grid-column: 1 / 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-row: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}

.change-password > form > div:nth-child(2) {
  grid-column: 1 / 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
}

.change-password > form > div:nth-child(3) {
  grid-column: 2 / 3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
}

.change-password > form > div:nth-child(4) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-column: 3 / 4;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
}

.change-password > form > div:nth-child(2)
.change-password > form > div:nth-child(3)
.change-password > form > div:nth-child(4) {
  grid-row: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}

.change-password form label {
  line-height: 30px;
}

.change-password form label:after {
  content: ':';
}

.change-password form input {
  height: 34px;
  width: 100%;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-appearance: none;
}

.change-password form > div input.is-invalid-input {
  box-shadow: inset 0 0 0 1px #D90348;
  -webkit-box-shadow: inset 0 0 0 1px #D90348;
  -webkit-appearance: none;
}

.change-password form > div .form-error {
  color: #D90348;
  font-size: 12px;
}

.change-password__submit_button {
  background-color: #D90348;
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 0 14px;
  padding: 0 14px;
  border: none;
  font-weight: bold;
  font-size: 17px;
  line-height: 34px;
  height: 34px;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
}

.change-password__submit_button:disabled{
  background-color: gray;
  cursor: not-allowed;
}
