@media screen and (max-width: 499px) {

.alerts-page_summary-row{
    flex-direction: column;
}
.alerts-page_mobile-alert-item .frame-section_title {
    color: #173a64;
    
}
.alerts-page_mobile-alert-item .frame-section_title svg{
fill: #173a64;
}
.alerts-page_mobile-alert-item{
    border-bottom: 1px solid #d7d7d7;;
}
}