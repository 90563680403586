.alerts-page_main-content{
    grid-column-start: 1;
    grid-column-end: 13;
    color: #004070
}

.alerts-page_summary-row{
    display: flex;
    justify-content: space-between;
}

.alerts-page_collapse{
    margin-top:10px;
    margin-bottom: 10px;
}
.alerts-page_collapse-title{
    align-items: center;
}
.alerts-page_collapse-title div{
    display: flex;
    align-items: center;
}

.alerts-page_collapse-content{
    background: white;
    padding: 10px
}

.alerts-page_content-table{
    width: 100%;
    border-collapse: collapse;
    border: none
}
.alerts-page_content-table th{
    border: none;
    text-align: left;
    padding-left:0
}

.alerts-page_content-table tbody tr{
    border-bottom: 1px solid #D7D7D7;
}

.alerts-page_content-table tr td:first-child{
    width: 150px;
}

.alerts-page_content-table tr td:nth-child(2){
    width: 150px;
}
.alerts-page_content-table tr td:last-child{
    width: 250px;
}
.alerts-page_collapse-desc{
    font-size: 14px
}
.alerts-page_collapse-desc span{
    padding-right: 12px;
}