@media all and (max-width: 499px) {

  div.alert-template-change-sms-email-popup-header h4 {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 10px;
  }

  div.alert-template-change-sms-email-popup-header p {
    margin: 0;
  }

  .update-email-section h3, .update-sms-section h3 {
    margin-bottom: 0;
    color: #cb1d4b;
  }

  div.page_id_alert_templates {
    grid-column: 1/27;
    padding: 20px 20px 300px 20px;
  }

  div.page_id_alert_templates h2 {
    font-size: 24px;
  }

  .alert_templates_instructions {
    font-size: 14px;
  }

  .save-alert-templates-buttons {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: space-between
  }

  .save-alert-templates-buttons .button {
    display: inline-block;
    width: 100px !important;
    margin: 0;
    height: auto;
    margin: 0 0 0 10px;
  }

  .alert_templates_standard_contact_instructions {
    font-size: 14px;
  }

  .alert_templates_standard_contact_instructions {
    padding-top: 10px;
  }

  .leaving-confirmation {
    margin: 10px;
  }

  .leaving-confirmation h4 {
    font-size: 23px
  }

  .page_id_alert_templates-with-controls {
    display: block;
  }

  .update-email-section {
    grid-column: 1/13;
  }

  .update-sms-section {
    grid-column: 1/13;
    grid-row: 2;
  }

  .update-section-field {
    height: auto;
    margin-top: 12px;
  }

  .update-section-field > div {
    display: inline-block;
    margin-left: 0;
    width: 100%;
  }

  .alert-template-change-sms-email-popup-footer {
    grid-row: 3;
  }

  .update-section-field > label {
    position: relative;
    display: block;
    width: 100%;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .alert-template-change-sms-email-popup-form input {
    width: 100%;
  }

  .alert-template-change-sms-email-popup-footer-controls {
    grid-column: 1/12;
    margin-left: 0;
    text-align: center;
  }

  .alert-template-change-sms-email-popup-more-info {
    text-decoration: underline;
    grid-column: 1/12;
    text-align: center;
    margin: 20px 0 10px;
  }

  .alert-template-change-sms-email-popup-footer .react-hint {
    width: 70vw;
  }

  .update-sms-section {
    margin-left: 0 !important;
  }

  div.collapsible-header-trigger {
    font-size: 26px;
  }

  div.alert-templates-collapsible-trigger,
  h4.alert-template-status-name,
  div.alert-template-value-cell {
    font-size: 13px;
  }

  .app-modal.alert-template-change-sms-email-popup {
    top: 50vh;
    max-height: 90vh;
    overflow: scroll;
  }

  .app-modal.alert-template-change-sms-email-popup > div{
    padding: 20px;
  }

  .update-sms-section .update-section-field > div {
    margin-left: 0;
  }

  .save-alert-templates-buttons{
    width: 100%
  }

}