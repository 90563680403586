@media screen and (max-width: 499px) {
  .sign-out {
    margin-top: -25px;
  }

  .page_id_saved-lists-header h4{
    margin: 15px 0 15px;
  }

  .react-hint--right{
    left:50px !important;
  }

}