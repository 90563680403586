.account-notes-title {
  grid-column-start: 1;
  grid-column-end: 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  color: #36B4E5;
  font-size: 34px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: left;
}

.account-notes-table {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
  width: 100%;
  max-height: 30px;
  border-collapse: collapse;
  word-break: break-all;
  border-bottom: 1px solid #E1E1E1;
}

.account-notes-table tr {
  border-left: 1px solid #E1E1E1;
  border-right: 1px solid #E1E1E1;
}

.account-notes-table td {
  border: 1px solid #E1E1E1;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 14px;
  color: #003865;
  background-color: #fff;
}

.account-notes-table td {
  font-size: 14px;
}

.account-notes-table td:nth-child(1) {
  min-width: 100px;
}

.account-notes-table td:nth-child(2) {
  min-width: 60px;
}

.account-notes-table td:nth-child(3) {
  width: 100%;
}

.account-notes-table-wrapper {
  margin-top: 10px;
}

.add-note-form {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto;
  -ms-grid-columns: 1fr 1fr;
}

div.new-note-textarea-wrapper {
  grid-column: 1;
  -ms-grid-column: 1;
}

div.new-note-textarea-wrapper div {
  height: 100%;
  width: 100%;
}

div.new-note-textarea-wrapper .new-note {
  width: 100%;
  height: 100%;
  resize: none;
}

div.add-note-button-wrapper {
  grid-column: 2;
  -ms-grid-column: 2;
  margin: 20px;
}

div.no_records_yet {
  text-align: center;
  margin: 20px;
  font-size: 25px;
}

div.add-note-button-wrapper button:disabled {
  background-color: gray;
  cursor: not-allowed;
}