@supports (-ms-ime-align:auto) {
  .acceptable_use_policy_main_wrap section {
    margin: 70px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .acceptable_use_policy_main_wrap section {
    margin: 70px 0 0 0;
  }

  .page_acceptable_use_policy{
    overflow-y: scroll;
  }

  .ie_gap{
    height: 100px;
  }
}

.logged-in-layout__navbar + div.page_acceptable_use_policy {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
  -ms-grid-row: 1;
}

.logged-in-layout__navbar_collapsed + div.page_acceptable_use_policy {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
  -ms-grid-row: 1;

}

.page_acceptable_use_policy > h1 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
  background-color: #36b4e5;
  margin: 0;
  padding: 10px 0 10px 40px;
  color: #fff;
  text-align: center;
}

.page_acceptable_use_policy h3.marginMainPoints{
  margin-top: 30px;
  margin-bottom: -10px;
}

.page_acceptable_use_policy section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;

  text-align:justify;
}

.page_acceptable_use_policy legend {
  font-weight: bold;
  padding:40px 0 0 30px;
}

.page_acceptable_use_policy form>div {
  clear: both;
  text-align: right;
  padding: 10px 20px;
}

.visibleDiv{
}

.invisibleDiv{
  display: none;
}

.page_acceptable_use_policy a.site{
  text-decoration: underline;
  color: #36B4E5;
}

.page_acceptable_use_policy ul {
  list-style-type: disc;
}

.page_acceptable_use_policy ul.circle {
  list-style-type: circle;
}

.page_acceptable_use_policy ol.withoutPoint {
  counter-reset: item;
  list-style-type: none;
}

.page_acceptable_use_policy li.withoutPoint {
  display: block;
}

.page_acceptable_use_policy li.withoutPoint:before {
  content: counter(item) " ";
  counter-increment: item;
}

.page_acceptable_use_policy div.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_acceptable_use_policy button {
  margin: 40px 0px;
  text-align: center;
}

ul.internal-ul li {
  margin: 0 !important;
}
