@keyframes alert-frame {
  0%, 0.01% {
    transform: translate(0, 0);
    visibility: visible;
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@-webkit-keyframes alert-frame {
  0%, 0.01% {
    -webkit-transform: translate(0%, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate(-100%, 0);
  }
}

@-moz-keyframes alert-frame {
  0%, 0.01% {
    -moz-transform: translate(0%, 0);
    visibility: visible;
  }
  100% {
    -moz-transform: translate(-100%, 0);
  }
}

div.critical-alert-container {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;

  -webkit-animation: alert-frame 23s infinite cubic-bezier(0,0,1,1);
  -moz-animation: alert-frame 23s infinite cubic-bezier(0,0,1,1);
  animation-name: alert-frame;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0,0,1,1);
}

div.critical-alert-wrapper{
  overflow: hidden;
  padding-left: 100%;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

span.critical-alert-content {
  color: #D90348;
  font-size: 20px;
}

