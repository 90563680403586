.container{
    width: 100%;
    padding: 30px 30px 200px 30px;
    background: #F0EEEE;
    box-sizing: border-box;
}
.header{
    font-weight: bold;
    font-size: 32px;
    color: #36b4E5;
    margin: 0 0 15px 0
}
.table{
    width: 100%;
    border-collapse: collapse
}
.tableHeader{
    background: #FFB81D;
    color:white;
    font-size: 18px;
    font-weight: bold;
}
.tableHeader td{
    padding:15px;
    text-align: center
}
.tableContent{
    background: white;
    color: #173A63;

}
.tableContent td{
    padding: 15px;
    text-align: center
}

.dateColumn{
    text-align: left!important;
}

.showMoreRow{
    text-align: center;
    color: #173A63;
}

.buttonRow{
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    
}

.buttonView{
    color:#173A63;
    background: white;
    border: 1px solid #173A63;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-decoration: none;
}

.buttonView:hover{
    color:white;
    background: #173A63;
}


.frame{
    margin-bottom: 10px;
    color:#173A63;
}
.title{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
}

.sectionRow{
    padding: 5px;
    border-bottom: 1px solid #173A63; 
}
.sectionRow:last-child{
    border-bottom:none
}
.flexRow{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 499px) {
    .container{
        height: 100%;
        padding: 15px 15px 300px 15px;
    }
    .header{
        font-size: 26px;
    }
    .buttonRow{
        justify-content: space-around;
    }
}