@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_containers > section {
    margin: 20px 0 20px 0;
    padding-bottom: 180px;
  }

  div.react-cookie-banner + div.page_id_dashboard {
    margin-top: 45px;
  }

  .search-section-bottom button {
    padding-right: 75px !important;
  }

  .page_id_dashboard {
    padding-bottom: 180px !important;
  }

  .page_id_dashboard > div > h2 {
    padding-left: 20px;
  }

  .page_id_dashboard > div > h4 {
    padding-left: 20px;
  }

  div.header_critical_alert_wrapper {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  h2.dashboard_header, h4 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  div.dashboard_coming-soon-section > .slick-initialized {
    margin-bottom: 20px;
  }

  .dashboard-search-controls-section .react-hint {
    margin-top: -5px !important;
  }

  div.fake-free-history-box,
  div.fake-free-alerts-box {
    left: 20px;
    top: 20px;
    width: calc(100% - 40px) !important;
    height: calc(100% - 40px) !important;
  }

}

@supports (-ms-ime-align:auto) {
  div.react-cookie-banner + div.page_id_dashboard {
    margin-top: 45px;
  }

  .page_id_dashboard > div > h2 {
    padding-left: 20px;
  }

  .page_id_dashboard > div > h4 {
    padding-left: 20px;
  }

  div.header_critical_alert_wrapper {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  h2.dashboard_header, h4 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  div.dashboard_coming-soon-section > .slick-initialized {
    margin-bottom: 20px;
  }

  .dashboard-search-controls-section .react-hint {
    margin-top: -5px !important;
  }

  div.fake-free-history-box,
  div.fake-free-alerts-box {
    left: 20px;
    top: 20px;
    width: calc(100% - 40px) !important;
    height: calc(100% - 40px) !important;
  }

}

.form-error {
  display: block;
}

.section-link {
  display: flex;
  align-self: flex-end;
  width: 50px;
  margin-top: 10px;
}

.page_id_dashboard {
  background: #F0EEEE;
}

div.page_id_dashboard {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr) !important;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  padding: 30px 30px 180px 30px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.page_id_dashboard section:nth-child(1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
}

.dashboard_container-search-section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  padding: 0;
  -ms-grid-row: 2;
}

.dashboard_my-account-section {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 7;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_my-account-section_old {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 9;
  -ms-grid-column-span: 4;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_alerts-section {
  grid-column-start: 7;
  -ms-grid-column: 7;
  grid-column-end: 9;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_history-section {
  grid-column-start: 9;
  -ms-grid-column: 9;
  grid-column-end: 11;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_faq-section {
  grid-column-start: 11;
  -ms-grid-column: 11;
  grid-column-end: 13;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.dashboard_faq-section_old {
  grid-column-start: 9;
  -ms-grid-column: 9;
  grid-column-end: 13;
  -ms-grid-column-span: 4;
  -ms-grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_my-account-section .button,
.dashboard_faq-section .button {
  width: 10px;
}

.dashboard_faq-section .button {
  align-self: flex-end;
}

.dashboard_faq-section-content {
  flex-grow: 2;
}

.dashboard_faq-section-content > p {
  margin-top: 0;
}

.dashboard_port-status-notifications-section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 7;
  -ms-grid-column-span: 6;
  -ms-grid-row: 4;
}

@media screen and (min-width: 500px) {
  .dashboard_coming-soon-section {
    grid-column-start: 7 !important;
    -ms-grid-column: 7 !important;
    grid-column-end: 13 !important;
    -ms-grid-column-span: 6 !important;
    -ms-grid-row: 4 !important;
  }
}

.dashboard_coming-soon-section {
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

section.dashboard_coming-soon-section {
  padding: 20px !important;
}

.slick-slider, .slick-list, .slick-track, .slick-slide > div {
  height: 100% !important;
}

.slick-dots {
  bottom: 15px !important;
}

.search-numbers {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  resize: none;
  overflow-y: scroll;
  padding: 10px;
}

.search-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-form section {
  margin: 0;
}

.search-form > div:nth-child(1) {
  display: flex;
  flex-flow: column;
  flex: 1;
}

.search-section-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-section-bottom label {
  height: auto;
  margin: 10px 0 10px
}

.search-section-bottom button {
  margin-bottom: 5px;
}

.search-numbers + .form-error.is-visible {
  margin: 5px 0;
  color: #D90348;
  font-size: 12px;
}

.page_id_dashboard > div {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 7;
}

.page_id_dashboard > div > h2 {
  color: #36B4E5;
  font-size: 34px;
  margin: 0 0 15px 0;
}

.page_id_dashboard > div > h4 {
  color: #D90348;
  font-size: 20px;
  margin: 0;
}

.dashboard_contactInfo {
  grid-column: 5;
  -ms-grid-column: 5;
  grid-column-end: 12;
  -ms-grid-column-span: 8;
  -ms-grid-row: 5;
  margin-left: 7%;
}

.dashboard-search-controls-section {
  grid-column: 1/5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  -ms-grid-row: 3;
  padding: 0;
}

.dashboard-search-controls-section > button {
  float: right;
}

.dashboard-hint-wrapper .react-hint {
  text-align: center;
  width: 10vw;
}

.mobile-section-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.changed-activity-modal {
  text-align: center;
}

.changed-activity-modal-container-list {
  margin: 10px 0 10px;
}

.changed-activity-modal-controls {
  width: 100%;
  text-align: center;
}

.page_id_dashboard > div {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 13;
}

div.critical-alert-wrapper {
  width: 100%;
}

.dashboard_coming-soon-section-div {
  min-height: 200px;
}

.dashboard_coming-soon-section-div > p:first-of-type {
  margin-top: 0;
}

.faq-dashboard-section-button-wrapper {
  display: inline-block;
}

.faq-dashboard-section-button-wrapper > a {
  float: right;
}

.disabled-button {
  background-color: #D2D1D1 !important;
}

.section_color_grey {
  background-color: #afafaf;
}

.dashboard_alerts-section-wrapper,
.dashboard_history-section-wrapper{
  position: relative;
}

span.dashboard_alerts-section-wrapper,
span.dashboard_history-section-wrapper {
  display: flex;
  justify-content: space-between;
}

div.fake-free-history-box,
div.fake-free-alerts-box {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.fake-free-alerts-box > span,
div.fake-free-history-box > span {
  border-radius: 5px;
  padding: 10px;
  font-size: 1em;
  color: white;
  background-color: #173A64;
  width: 180px;
  display: none;
  position: relative;
}

div.fake-free-alerts-box:hover > span,
div.fake-free-history-box:hover > span {
  display: block;
}

span.dashboard_alerts-section-wrapper {
  grid-column-start: 7;
  grid-column-end: 9;
  -ms-grid-column: 7;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
}

span.dashboard_history-section-wrapper {
  grid-column-start: 9;
  grid-column-end: 11;
  -ms-grid-column: 9;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
}

span.dashboard_alerts-section-wrapper > section,
span.dashboard_history-section-wrapper > section {
  width: 100%;
  padding: 20px;
}

.dashboard_wrap .react-hint--top {
  margin-top: -5px;
  margin-left: 30px;
}

.dashboard_wrap .react-hint--bottom {
  margin-top: 5px;
}

.dashboard_wrap .react-hint__content {
  width: 180px
}

div.dashboard_saved-list-button-wrapper {
  display: inline-block;
}

.dashboard_alerts-section-wrapper .react-hint,
.dashboard_history-section-wrapper .react-hint {
  margin-left: 0;
}

div.header_critical_alert_wrapper >  div.critical-alert-wrapper{
  margin-top: -20px;
}