@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  div.page_id_faqs li {
    margin-left: 20px;
  }
}

.page_id_faqs {
  background: #F0EEEE;
  padding-bottom: 30px !important;
}

.page_id_faqs section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
  margin-bottom: 110px;
}

a {
  color: #0000EE;
}