@supports (-ms-ime-align:auto) {
  .page_id_administration_search section {
    margin: 70px 0 0 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  react-app {
    overflow-y: scroll;
  }

  .page_id_administration_search section {
    margin: 70px 0 0 0;
    padding-bottom: 150px;
  }

  .page_id_helpdesk_account{
    overflow-y: scroll;
  }
}

.logged-in-layout__navbar + div.page_id_administration_search {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
}

.logged-in-layout__navbar_collapsed + div.page_id_administration_search {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 27;
  -ms-grid-column-span: 25;
}

.page_id_administration_search {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 30px 30px 180px 30px;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
  background-color: #F0EEEE;
  grid-template-rows: fit-content(200px);
}

.page_id_administration_search > h1 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 14;
  background-color: #36b4e5;
  margin: 0;
  padding: 10px 0 10px 40px;
  color: #fff;
  text-align: center;
}

.page_id_administration_search section {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
}

.page_id_administration_search form {
  padding: 25px 10px;
}

.page_id_administration_search fieldset {
  border: none;
  width: 50%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.page_id_administration_search fieldset > div {
  padding: 0 15px 0 135px;
  position: relative;
}

.page_id_administration_search fieldset > div + div {
  margin-top: 10px;
}

.page_id_administration_search fieldset > div label {
  position: absolute;
  left: 0px;
  width: 120px;
  text-align: right;
  font-weight: bold;
  line-height: 34px;
}

.page_id_administration_search fieldset > div input {
  -webkit-appearance: none;
}

.page_id_administration_search fieldset > div input,
.page_id_administration_search fieldset > div select {
  height: 34px;
  width: 99%;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #D2D1D1;
  -webkit-box-shadow: inset 0 0 0 1px #D2D1D1;

  outline: none;
  background: #fff;
}

.page_id_administration_search fieldset:nth-child(1) {
  float: left;
}

.administration_search_buttons {
  clear: both;
  text-align: right;
}

.administration_search_buttons button {
  margin: 20px;
}

.administration_search_submit_button {
  padding: 0px 25px 0 16px !important;
}

.administration_search_buttons svg {
  position: absolute;
  top: 27%;
  right: 5px;
}

.button:disabled {
  background-color: #afafaf;
  cursor: not-allowed;
}

.no_records_found {
  text-align: center;
  font-size: 35px;
}

.administration-search-table-wrapper {
  padding: 0 30px;
}

.administration-search-table {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 13;
  -ms-grid-column-span: 12;
  width: 100%;
  max-height: 30px;
  border-collapse: collapse;
  word-break: break-all;
  border-bottom: 1px solid #E1E1E1;
}

.administration-search-table tr {
  border-top: 1px solid #FFB81D;
  border-left: 1px solid #E1E1E1;
  border-right: 1px solid #E1E1E1;
}
.administration-search-table th {
  background-color: #36B4E5;
  color: #fff;
  text-align: left;
  padding: 15px 10px 15px;
}

.administration-search-table td {
  padding: 10px 10px;
  font-weight: bold;
  font-size: 14px;
  color: #003865;
  background-color: #fff;
}

.show_more {
  padding-top: 20px;
  text-align: center;
  cursor: pointer;
}

.administration-search-table tr:hover td {
  background-color: #FFE2A4;
}

.administration_search_table_cell_service_type{
  min-width: 120px;
}

.administration_search_table_cell_status{
  min-width: 90px;
}