.frame-section {
  /* border: 1px solid #173A64; */
  display: flex;
  flex-flow: column nowrap;
}

.frame-section_borderless {
  border: none;
}

.frame-section_title-color_dark-blue {
  background-color: #173A64;
}
.frame-section_title-color_blue {
  background-color: #36B4E5;
}
.frame-section_title-color_yellow {
  background-color: #FFB81D;
}

.frame-section_title-color_pink {
  background-color: #D90348;
}

.frame-section_title-color_yellow {
  background-color: #FFB81D;
}

.frame-section_title-color_dark-blue {
  background-color: #173A64;
}

.frame-section_title-color_white {
  background-color: #ffffff;
}
.frame-section_title-color_white > svg {
  fill: #173A64 !important;
}

.frame-section_title {
  display:flex;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  padding: 5px;
}

.frame-section_content {
  display: inline-block;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

