@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .alert_templates_standard_contact_instructions{
    margin-top: 20px !important;
    padding-bottom: 150px;
  }
  .page_id_alert_templates {
    overflow-y: scroll;
  }
  .activity_alert_template-content {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .status-alert-configuration-cell{
    flex-grow: 1;
    flex-basis: 0;
  }
}

@supports (-ms-ime-align:auto) {
  div.page_id_alert_templates button{
    margin-bottom: 20px;
  }
}

.page_id_alert_templates{
  padding: 15px;
  overflow: hidden;
}

.alert-template__header{
  font-size: 18.72px;
  font-weight: 800;
  margin-left: 10px!important
}

.page_id_alert_templates-content{
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  -ms-grid-row: 3;
}
.alert_templates_instructions{
  -ms-grid-row: 2;
}


.page_id_alert_templates {
  background: #F0EEEE;
}

.alert_templates_instructions {
  grid-column: 1/24;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  font-weight: normal;
  margin: 10px;
  color: #173A64;
  font-size: 18px;
}

.alert_templates_standard_contact_instructions{
  font-size: 18px;
}

.alert_templates_standard_contact_instructions a {
  color: #173A64;
}

.page_id_alert_templates-content {
  grid-column: 1/24;
}
.page_id_alert_templates-content-row{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}
.save-alert-templates-buttons {
  align-self: flex-end;
  display: flex;
  align-items: center
}


.save-alert-templates-buttons .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  margin: 0 0 0 50px;
}

.alert-template-saved-message{
  color: green;
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.save-alert-templates-buttons .cancel-button {
  font-size: 17px;
  margin: 10px 0 10px;
  white-space: normal;
  line-height: 20px;
}

.leaving-confirmation-buttons {
  text-align: center;
  padding: 30px 0 0 0;
}

.page_id_alert_templates-with-controls {
  display: flex;
}

.change-alert-email-sms-link {
  cursor: pointer;
  text-decoration: underline;
}

.alert-template-change-sms-email-popup-header {
  text-align: center;
}

.alert-template-change-sms-email-popup-form {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.alert-template-change-sms-email-popup-form > div {
  margin: 10px 0 10px;
}

.alert-template-change-sms-email-popup-form input {
  color: #173A64;
}

.update-section-field {
  height: 45px;
}

.update-email-section {
  grid-column: 1/7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  -ms-grid-row: 2;
  display: grid;
  grid-row: 1;
}

.update-sms-section {
  grid-column: 6/13;
  -ms-grid-column: 6;
  -ms-grid-column-span: 7;
  grid-row: 1;
  -ms-grid-row: 2;
  margin-left: 20px !important;
}

.update-section-field > div {
  display: inline-block;
  margin-left: 140px;
}

.update-sms-section .update-section-field > div {
  margin-left: 160px;
}

.update-section-field > label {
  position: absolute;
}

.update-email-section h3, .update-sms-section h3 {
  color: #cb1d4b;
}

.alert-template-change-sms-email-popup-footer {
  grid-column: 1/13;
  grid-row: 2;
  -ms-grid-row: 4;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-template-areas:
      "a a a a a b b b"
}

.alert-template-change-sms-email-popup-footer-info {
  grid-area: a;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
}

.alert-template-change-sms-email-popup-footer-controls{
  grid-area: b;
  -ms-grid-column: 6;
  -ms-grid-column-span: 3;
  margin-left: 35px;
}

.alert-template-change-sms-email-popup-footer-controls > button {
  display: inline-block;
  margin-right: 20px;
}

.alert-template-change-sms-email-popup .form-error {
  color: #D90348;
  font-size: 12px;
}

.app-modal.alert-template-change-sms-email-popup {
  top: 45%;
}

.read-only-input {
  border: none;
  font-size: 16px;
}

.form-error.invisible {
  display: none;
}

.alert-template-contacts-update-button {
  background-color: #D90348;
  color: #fff;
  border: none;
  padding: 0 15px 0;
  font-weight: bold;
  font-size: 17px;
  line-height: 34px;
  height: 34px;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
}

.alert-template-contacts-update-button:disabled {
  background-color: #828282;
  opacity: 0.5;
  cursor: not-allowed;
}

.alert-template-change-sms-email-popup-content form input.is-invalid-input {
  text-indent: 5px;
  border: solid 1px #D90348;
  -webkit-appearance: none;
}

.alert-template-change-sms-email-popup input {
  border-width: thin;
}

.alert-templates__buttonRow{
  grid-column: 1/24;
  -ms-grid-column: 1;
  -ms-grid-column-span: 24;
  grid-row: 4;
  -ms-grid-row: 4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap

}

.alert-templates__buttonRow_text{
  margin-left: 10px;
  min-width: 250px;
  color: #173A64;
}

