@media screen and (max-width: 499px) {

  div.page_id_register{
    padding: 20px 20px 300px 20px;
  }

  div.page_id_register > h1 {
    padding: 5px;
    font-size: 20px;
  }

  div.page_id_register > section{
    padding: unset;
  }

  div.page_id_register > section > form{
    padding: 10px;
  }

  div.page_id_register > section > form > div{
    padding: 10px 0 0 0;
    text-align: center;
  }

  div.page_id_register > section > form > div > button{
    font: bold 14px/34px 'Tahoma';
    margin-bottom: 10px;
  }

  div.page_id_register > section > form > div > button:first-child{
    float: left;
  }

  div.page_id_register > section > form > div > button:last-child{
    float: right;
  }

  div.page_id_register > section > form > fieldset{
    width: 100%;
  }

  div.page_id_register > section > form > fieldset > div{
    padding: 0 0 10px 0;
    margin: unset;
  }

  div.page_id_register > section > form > fieldset > div > label {
    display: block;
    position: unset;
    left: unset;
    text-align: left;
    height: unset;
    line-height: unset;
    padding: 0 0 5px 0;
    width: 100%;
    font-size: 14px;
  }

  div.page_id_register > section > form > fieldset > div > div{
    padding: unset;
  }

  div.page_id_register > section > form > fieldset > div > div > input{
    font-size: 14px;
    height: 30px;
  }

  div.page_id_register > section > form > fieldset > div > div > select,
  .registration__role-in-company > div > :nth-last-child(2){
    text-indent: unset;
    padding-left: 6px;
    white-space: pre-wrap;
  }

  div.page_id_register > section > form:nth-child(1) > fieldset:nth-child(1) > div:nth-child(5) > div:nth-child(2) {
    left: 85%;
  }

  div.page_id_register > section > form:nth-child(1) > fieldset:nth-child(1) > div:nth-child(5) > div:nth-child(2):after {
    position: absolute;
    right: -60%;
    left: unset;
    margin-top: 10px;
  }

  div.page_id_register > section > form > fieldset:nth-child(1) > div:nth-child(5) > div > span,
  div.page_id_register > section > form > fieldset:nth-child(1) > div:nth-child(6) > div > span{
   width: 80%;
  }

  div.page_id_register fieldset > div input[name="password"],
  div.page_id_register fieldset > div input[name="confirm-password"]{
    width: 80%;
  }

  div.page_id_register fieldset > div input[name="postcode"],
  div.page_id_register fieldset > div input[name="contact-number"],
  div.page_id_register fieldset > div input[name="containers-number"]{
    width: 100%;
  }

  div.page_id_register > section > form > fieldset:nth-child(3) > legend {
    padding: unset;
  }

  .register__update_container input[type='radio'],  .register__update_container input[type='checkbox'] {
    height: 18px!important;
    width: 18px!important;
    border: 1px solid #173a64;
  }

  
  .register__flex-ports{
    flex-direction: column;
  }
  .register__update_container{
    margin: 0px!important;
  }
  .register__subtitle{
    text-align: left;
  }
  .register__button_row{
    float: none!important;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 315px) {

  div.page_id_register > section > form > div > button{
    font: bold 12px/34px 'Tahoma';
  }

  .registration_button_back {
    margin-right: 10px;
  }

}