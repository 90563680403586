@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_containers-history-controls {
    margin-top: 15px;
  }
}

@supports (-ms-ime-align:auto) {
  .page_id_containers-history-controls {
    margin-top: 15px;
  }
}

.page_id_containers-history-controls {
  grid-column: 1/13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  -ms-grid-row: 3;
  text-align: right;
}