@media all and (max-width: 499px) {

  .change-password {
    display: block;
    margin: 10px;
  }

  .change-password > h4{
    font-size: 23px;
    line-height: 34px;
  }

  .change-password > div {
    margin: 5px 0 5px 0;
  }

  .change-password > form {
    display: block;
  }

  .change-password form label{
    font-size: 15px;
  }

  .change-password form input{
    height: 32px;
  }

  .change-password > form > div:nth-child(4){
    margin: 20px 0 0 0;
    grid-row: 5/8;
    grid-column: 1/4;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4,1fr);
  }

  .change-password__submit_button{
    grid-column: 4/auto;
    margin: 0;
  }

}