@supports (-ms-ime-align: auto) {
  .page_id_account section {
    margin: 20px 20px 20px 0;
  }

  .my-account-trial-advanced-wrapper-free {
    margin-right: 20px;
  }

  .my-account-trial-advanced-wrapper-free,
  .my-account-delete-account-section {
    margin-top: 0 !important;
  }

  .my-account-trial-advanced-wrapper-advanced {
    margin-right: 20px;
    margin-top: -54px;
    margin-bottom: 20px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_id_account {
    padding-bottom: 180px !important;
  }

  .my-account-trial-advanced-wrapper-free {
    margin-right: 20px;
  }

  .page_id_account section {
    margin: 20px 20px 20px 0;
  }

  .my-account-trial-advanced-wrapper-free,
  .my-account-delete-account-section {
    margin-top: 0 !important;
  }

  .my-account-trial-advanced-wrapper-advanced {
    margin-right: 20px;
    margin-top: -54px;
    margin-bottom: 20px;
  }

  .my-account-delete-account-section button.button_link {
    width: 47px !important;
  }
}

.page_id_account {
  background: #f0eeee;
  color: #173a64;
}

.page_id_account > h2 {
  grid-column: 1 / 12;
  -ms-grid-column: 1;
  -ms-grid-column-span: 11;
  grid-row: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}

.page_id_account-general-information-section-free {
  grid-column: 1 / 7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-row: 2 / 4;
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
}

.page_id_account-general-information-section {
  grid-column: 1 / 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-row: 2 / 4;
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
}

.page_id_account section:nth-child(2),
.page_id_account section:nth-child(3) {
  background-color: #fff;
}

.page_id_account section:nth-child(2) > h4,
.page_id_account section:nth-child(3) > h4 {
  color: #cb1d4b;
}

.account-details-form,
.access-information-form {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  color: #173a64;
}

.account-details-form > div,
.access-information-form > div {
  grid-column: 1 / 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 0px;
  grid-column-gap: 20px;
}

.account-details-form > div > div > div {
  display: flex;
  align-items: center;
  height: 34px;
  text-indent: 20px;
  grid-column: 4 / 13;
  -ms-grid-column: 4;
  -ms-grid-column-span: 9;
}

.account-details-form > div > div,
.access-information-form > div > div {
  display: block;
  align-items: center;
  height: 34px;
  text-indent: 10px;
  grid-column: 4 / 13;
  -ms-grid-column: 4;
  -ms-grid-column-span: 9;
  margin: 5px 0 5px 0;
}

.account-details-form > div > label,
.access-information-form > div > label {
  display: flex;
  align-items: center;
  grid-column: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
}

.account-details-form input {
  width: 90%;
  height: 34px;
  color: #173a64;
  text-indent: 10px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0 0 0 1px #d2d1d1;
  -webkit-box-shadow: inset 0 0 0 1px #d2d1d1;
  -webkit-appearance: none;
}

.account-details-form input[name="name"],
.account-details-form input[name="email"] {
  box-shadow: none;
}

.account-details-form select {
  height: 34px;
  width: 55%;
  box-sizing: border-box;
  text-indent: 10px;
  border: none;
  box-shadow: inset 0 0 0 1px #d2d1d1;
  -webkit-box-shadow: inset 0 0 0 1px #d2d1d1;
  outline: none;
  color: #173a64;
  background: #fff;
  font-size: 14px;
}

.account-details-form > div > button {
  grid-column: 4 / auto;
  margin: 5px;
  -ms-grid-column: 4;
  -ms-grid-column-span: 10;
  -ms-grid-row: 7;
}

.access-information-form button {
  margin-left: 0px;
  -ms-grid-column: 4;
  -ms-grid-column-span: 2;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .access-information-form button {
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
  }
}

.account-details-form > div:nth-child(7) {
  display: flex;
  justify-content: center;
  grid-column: 2 / 4;
  -ms-grid-row: 7;
  -ms-grid-column-span: 1;
  -ms-grid-column: 2;
  margin-top: 10px;
  margin-left: 10px;
}

@supports (-ms-ime-align: auto) {
  .account-details-form > div > div {
    display: block;
    align-items: center;
    height: 34px;
    text-indent: 10px;
    grid-column: 4 / 13;
    -ms-grid-column: 4;
    -ms-grid-column-span: 9;
    margin: 15px 0 5px 0;
  }

  .account-details-form > div:nth-child(7) {
    display: flex;
    justify-content: space-between;
    grid-column: 2 / auto;
    -ms-grid-row: 7;
    -ms-grid-column-span: 1;
    -ms-grid-column: 2;
    margin-top: 20px;
    margin-left: 10px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .account-details-form > div > div {
    display: block;
    align-items: center;
    height: 34px;
    text-indent: 10px;
    grid-column: 4 / 13;
    -ms-grid-column: 4;
    -ms-grid-column-span: 9;
    margin: 15px 0 5px 0;
  }

  .account-details-form > div:nth-child(7) {
    display: flex;
    justify-content: flex-start;
    grid-column: 2 / auto;
    -ms-grid-row: 7;
    -ms-grid-column-span: 3;
    -ms-grid-column: 2;
    margin-top: 20px;
    margin-left: 10px;
  }
}

.page_id_account-access-information-section-free {
  grid-column: 7 / 12;
  -ms-grid-column: 7;
  -ms-grid-column-span: 5;
  grid-row: 2 / 3;
  -ms-grid-row: 2;
}

.page_id_account-access-information-section {
  grid-column: 5 / 9;
  -ms-grid-column: 5;
  -ms-grid-column-span: 4;
  grid-row: 2 / 3;
  -ms-grid-row: 2;
}

.page_id_account-package-details-section {
  grid-column: 5 / 9;
  -ms-grid-column: 5;
  -ms-grid-column-span: 4;
  grid-row: 3 / 4;
  -ms-grid-row: 3;
}

.page_id_account-payment-details-section-paid {
  grid-column: 9 / 13;
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
  grid-row: 3 / 4;
  -ms-grid-row: 3;
  max-height: 150px;
  z-index: 5;
}
.page_id_account-payment-details-section-free {
  grid-column: 1 / 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-row: 4 / 5;
  -ms-grid-row: 5;
  height: max-content;
}

.page_id_account-invoice_container {
  padding-top: 10px;
}

.page_id_account-invoice_link {
  padding-left: 3px;
  font-style: italic;
  color: #173a64;
}

.page_id_account-alert-preferences-section {
  grid-column: 9 / 13;
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
  grid-row: 2 / 3;
  -ms-grid-row: 2;
}

.page_id_account-delete-account-section {
  grid-column: 1 / 13;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  -ms-grid-row: 5;
}

.password-changed-massage {
  color: green;
  text-align: center;
  font-size: 24px;
}

.access-information-form div:nth-child(1),
.account-details-form div:nth-child(1) {
  -ms-grid-row: 1;
}

.access-information-form div:nth-child(2),
.account-details-form div:nth-child(2) {
  -ms-grid-row: 2;
}

.account-details-form div:nth-child(3) {
  -ms-grid-row: 3;
}

.account-details-form div:nth-child(4) {
  -ms-grid-row: 4;
}

.account-details-form div:nth-child(5) {
  -ms-grid-row: 5;
}

.account-details-form div:nth-child(6) {
  -ms-grid-row: 6;
}

.access-information-form div:nth-child(1) > div {
  display: flex;
  text-indent: 0;
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(1) > div {
  -ms-grid-row: 1;
}

.access-information-form div:nth-child(2) > div {
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(2) > div {
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(3) > div {
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(4) > div {
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(5) > div {
  -ms-grid-row: 1;
}

.account-details-form div:nth-child(6) > div {
  -ms-grid-row: 1;
}

.edit-button-wrapper {
  display: inline-block;
  grid-column: 1/5;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  -ms-grid-row: 3;
  text-align: center;
  text-indent: 0 !important;
}

.page_id_account-alert-preferences-section-content > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: #173a64;
}
.my-account-delete-account-section-paid {
  grid-column: 1/7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-row: 4;
  -ms-grid-row: 5;
}

.my-account-delete-account-section-free {
  grid-column: 1/7;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-row: 5;
  -ms-grid-row: 6;
}

.my-account-trial-advanced-wrapper-free {
  grid-column: 7 / 12;
  -ms-grid-column: 7;
  -ms-grid-column-span: 6;
  text-align: right;
  align-self: flex-end;
}

.my-account-trial-advanced-wrapper-free {
  grid-row: 4;
  -ms-grid-row: 5;
  align-self: start;
}

.my-account-trial-advanced-wrapper-free button {
  margin-left: 20px;
}

.my-account-trial-advanced-wrapper-advanced button {
  margin-left: 20px;
}

.my-account-trial-advanced-wrapper-advanced {
  grid-row: 4;
  grid-column: 9 / 13;
  -ms-grid-row: 5;
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: flex-end;
}

.my-account-trial-advanced-wrapper-paid {
  grid-row: 3;
  grid-column: 9 / 13;
  -ms-grid-row: 3;
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: flex-end;
}


.my-account-trial-advanced-wrapper-free > button,
.my-account-trial-advanced-wrapper-advanced > button,
.my-account-trial-advanced-wrapper-paid > button {
  white-space: pre-wrap;
  height: auto;
  line-height: 25px;
  align-self: flex-end;
}

.page_id_account-package-details-section-content-table {
  width: 100%;
  max-width: 500px;
  color: #173a64;
}

.page_id_account-package-details-section-content-table
  > tbody
  > tr
  > td:nth-child(2) {
  text-align: right;
}

.page_id_account-package-details-section-content-table
  > tbody
  > tr:nth-child(2)
  > td {
  padding: 10px 0 10px;
  vertical-align: top;
}

.page_id_account-alert-preferences-section-content > div > div:nth-child(1) {
  min-width: 100px;
}

.page_id_account-package-details-section-controls {
  text-align: center;
}

.page_id_account-advanced-features-section {
  grid-column: 7 / 12;
  -ms-grid-column: 7;
  -ms-grid-column-span: 5;
  grid-row: 3 / 4;
  -ms-grid-row: 3;
}

.page_id_account-advanced-features-section-advanced{
  grid-column: 9 / 13;
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
  grid-row: 3 / 4;
  -ms-grid-row: 3;
}

.advanced-features-section-header {
  font-size: 24px;
  color: #173a64;
}

.advanced-features-section-sub-header {
  font-size: 18px;
}

.frame-section_content > button {
  float: right;
}

.payment-modal__success_container {
  text-align: center;
}

.payment-modal__failed_container {
  color: red !important;
  text-align: center;
}

.paymnet-modal__header {
  font-weight: bold;
  font-size: 36px !important;
}

.paymnet-modal__subheader {
  font-size: 24px;
}

.paymnet-modal__text {
  font-size: 20px;
}

.paymnet-modal__modal #close-modal {
  display: none;
}

.page_id_account_cancel_subscription{
  margin-right: 10px;
}
.page_id_account-popup-header{
  color: #36B4E5;
  font-size: 34px;
}