@media screen and (max-width: 499px) {
  .page_id_containers > section {
    display: block;
    text-align: center;
  }

  .page_id_containers {
    padding: 20px 20px 380px 20px !important;
  }

  .container-list-controls {
    padding: 20px 0;
    border: 1px solid #FFB81D;
  }

  .container-list-controls button {
    width: 130px;
    font-size: 14px;
  }

  .page_id_containers {
    width: -moz-available;
  }

  .spinner-container img {
    display: block;
    margin: auto;
    height: 100px;
  }

  .table__legend {
    text-align: left;
  }

  .table__legend p {
    display: inline-block;
    width: auto;
  }

  .react-hint__content p {
    color: white;
    display: block;
  }

  .table__legend p:nth-child(3){
    margin-left: 40px;
    text-decoration: underline;
  }

  .container-amount-hint-wrapper .react-hint {
    max-width: 50vw;
    min-width: 100px;
  }

  .page_id_containers-found-containers-amount {
    margin-left: 5px;
  }

  .info-containers-amount {
    width: 10px;
    height: 10px;
    font-size: 10px;
    margin-top: 10px;

  }
}